import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject, OnInit, signal, WritableSignal
} from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { TuiButton, TuiDialogContext, TuiIcon, TuiLoader } from '@taiga-ui/core';
import { AddEventArtistForm, EditEventArtistForm } from '../../forms';
import { EventArtistEntity } from '@core/api/models/event-artist-entity';
import { FormArray, ReactiveFormsModule } from '@angular/forms';
import { EventService } from '@core/api/services';
import { of, switchMap, takeUntil } from 'rxjs';
import {
  TuiInputModule,
  TuiInputNumberModule,
  TuiTextareaModule
} from '@taiga-ui/legacy';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';
import { BaseAbstractModal } from '../../../base.abstract.modal';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'event-artist-component',
  templateUrl: './event-artist.modal.html',
  styleUrls: ['./event-artist.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiInputNumberModule,
    TuiButton,
    TuiIcon,
    ImagePickerComponent,
    TuiLoader
  ]
})
export class EventArtistModal extends BaseAbstractModal implements OnInit {
  public form: AddEventArtistForm | EditEventArtistForm;
  private eventService = inject(EventService);
  public image: WritableSignal<any> = signal(null);


  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        event_id: string;
        artist: EventArtistEntity | null;
      }
    >
  ) {
    super();


  }

  ngOnInit(): void {
    this.showLoader.set(true);
    const { artist } = this.data;

    if (!artist) {
      this.form = new AddEventArtistForm();
      this.showLoader.set(false);
    }

    if (artist) {
      this.form = new EditEventArtistForm({
        name: artist.name || '',
        description: artist.description || '',
        order: artist.order || 0,
        type: artist.type || '',
        links: artist.links || []
      });
      this.showLoader.set(false);
    }

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get data(): {
    event_id: string;
    artist: EventArtistEntity | null;
  } {
    return this.context.data;
  }

  get links() {
    // @ts-ignore
    return this.form.controls['links'] as FormArray;
  }

  pickImage(image: any) {
    this.image.set(image);
  }

  public onSubmit() {
    this.showLoader.set(true);
    const { artist } = this.data;
    if (!this.form.value) {
      this.showLoader.set(false);
      return;
    }

    if (!artist) {
      this.eventService
        .eventControllerCreateArtist({
          event_id: this.data.event_id,
          body: {
            name: this.form.value.name!,
            description: this.form.value.description!,
            order: this.form.value.order!,
            type: this.form.value.type!,
            links: this.form.value.links!
          }
        })
        .pipe(
          switchMap((res) =>
            this.image() && res.data?.id
              ? this.eventService.eventControllerUpdateArtistImage({
                id: res.data.id,
                body: { image: this.image() }
              })
              : of(res)
          ),
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false))
        )
        .subscribe((res) => this.context.completeWith(true));
    }

    if (artist) {
      this.eventService
        .eventControllerUpdateArtist({
          id: this.data.artist?.id!,
          body: { ...this.form.value }
        })
        .pipe(
          switchMap((res) =>
            this.image() && res.data?.id
              ? this.eventService.eventControllerUpdateArtistImage({
                id: res.data.id,
                body: { image: this.image() }
              })
              : of(res)
          ),
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false))
        )
        .subscribe((res) => this.context.completeWith(true));
    }
  }

  public onCancel() {
    this.context.completeWith(false);
  }
}
