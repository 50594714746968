import { TuiButton } from '@taiga-ui/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [TuiButton, NgClass]
})
export class PageHeaderComponent implements OnInit {
  @Input() title?: string;
  @Input() description?: string;
  @Input() actionTitle?: string;
  @Input() underline = false;
  @Output() action?: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
