<tui-loader
  [overlay]='true'
  [showLoader]='showLoader()'
>
  <div class='modal-content'>
    <div class='description'>
      <div class='description_holder'>
        <div class='description_holder_icon'>
          <tui-icon icon='@tui.file-text'></tui-icon>
        </div>
      </div>
      <h3 class='tui-text_h3 tui-space_bottom-4'>Що таке пункти програми і як створити повну програму?</h3>
      <p>
        Пункти програми це розділи розкладу вашого івенту. Наприклад з 17:00 до 18:00 у вас буде виступати гурт для
        розігріву глядачів, а з 18:00 до 20:00 хейдлайнери вашого концерту.
        <br>
        Кожен пункт програми потрібно додавати окрему для гармонічного відображення на сторінці вашого івенту.
        <br>
        Будь-лазка заповніть усі поля справа та натисніть створити, після чого пункт будет доданий до вашої програми.
      </p>
    </div>
    @if (form) {
      <div class='form-holder' [formGroup]='form'>
        <h2 class='tui-text_h2 tui-space_bottom-8'>Створити пункт програми</h2>
        <label tuiLabel> <input tuiCheckbox type="checkbox"
                                formControlName="active"
                                class='tui-space_bottom-4'
                                size="m"
        >
          Active
        </label>
        <tui-input
          class='tui-space_bottom-4'
          formControlName='name'>
          Назва квитка
        </tui-input>
        <tui-textarea
          formControlName='description'
          class='tui-space_bottom-4 field-small'
          [maxLength]='30'
          [expandable]='true'
        >
          Опис
        </tui-textarea>
        <div class='row'>
          <div class='col-xs-12'>
            <tui-input-number
              class='tui-space_bottom-4'
              formControlName='total_quantity'>
              Кількість квитків
            </tui-input-number>
          </div>
          <div class='col-xs-6'>
            <tui-input-number
              class='tui-space_bottom-4'
              formControlName='price'>
              Вартість квитка
            </tui-input-number>
          </div>
          <div class='col-xs-6'>
            <tui-select
              formControlName='currency'
            >
              currency
              <tui-data-list-wrapper
                *tuiDataList
                [items]='currencies'
              ></tui-data-list-wrapper>
            </tui-select>
          </div>
        </div>
        <label tuiLabel> <input tuiCheckbox type="checkbox"
                                formControlName="termless"
                                class='tui-space_bottom-4'
                                size="m"
        >
          Termless
        </label>
        @if (!form.value.termless) {
          <tui-input-date
            formControlName='end_date'
            class='tui-space_bottom-4'>
            Choose date
            <input
              placeholder='OCT 26 1985 01:22'
              tuiTextfieldLegacy
            />
          </tui-input-date>
          <tui-input-time
            formControlName='end_time'
            class='tui-space_bottom-4'
            [tuiTextfieldCleaner]='false'
            [items]='timePeriod'
          >
            Choose time
          </tui-input-time>
        }
        <div class='modal-footer'>
          <button
            tuiButton
            type='button'
            appearance='outline'
            (click)='onCancel()'
          >
            Cancel
          </button>
          <button
            tuiButton
            type='button'
            (click)='onSubmit()'
            [disabled]='form.invalid'
          >
            Save
          </button>
        </div>
      </div>
    }
  </div>
</tui-loader>
