import { TuiSwitch } from '@taiga-ui/kit';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemeService } from '@core/services/theme.service';

@Component({
  selector: 'app-theme-switcher',
  styleUrls: ['./theme-switcher.component.scss'],
  templateUrl: './theme-switcher.component.html',
  standalone: true,
  imports: [TuiSwitch, FormsModule]
})
export class ThemeSwitcherComponent {
  public enabled = true;
  public themeService = inject(ThemeService);

  constructor() {
    this.enabled = this.themeService.current() === 'dark';
  }

  onThemeSelect() {
    this.themeService.set(this.enabled ? 'dark' : 'light')
  }
}
