/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

export interface CompanyMembersControllerGetRoles$Params {}

export function companyMembersControllerGetRoles(
  http: HttpClient,
  rootUrl: string,
  params?: CompanyMembersControllerGetRoles$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Array<string>>> {
  const rb = new RequestBuilder(
    rootUrl,
    companyMembersControllerGetRoles.PATH,
    'get'
  );
  if (params) {
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
}

companyMembersControllerGetRoles.PATH = '/api/v1/company-members/roles';
