<tui-loader
  [overlay]='true'
  [showLoader]='showLoader()'
>
  <div class='modal-content'>
    <div class='description'>
      <div class='description_holder'>
        <div class='description_holder_icon'>
          <tui-icon icon='@tui.file-text'></tui-icon>
        </div>
      </div>
      <h3 class='tui-text_h3 tui-space_bottom-4'>Хто такі учасники івенту і як їх правильно додавати?</h3>
      <p>
        Учасники івенту це ваші спікери, музиканти, коміки, науковці та інші, яких ви хотіли би анонсувати на вашій
        сторінці.
        <br>
        Ви також можете додати лінки до соціальних мереж ваших учасників, щоб потенційні відвідувачі івенту могли більше
        дізнатися про ваших хедлайнерів! <br>
      </p>
    </div>

    @if (form) {
      <div class='form-holder' [formGroup]='form'>
        <h2 class='tui-text_h2 tui-space_bottom-8'>Створити пункт програми</h2>
        <p>Картинка пункту (рекомендований розмір 256х256px)</p>
        <app-image-picker
          [existImageUrl]="data?.artist?.img_url"
          (selectImage)="pickImage($event)"
        ></app-image-picker>
        <tui-input
          class='tui-space_bottom-4'
          formControlName='name'>
          Ім’я учасника
        </tui-input>
        <tui-textarea
          formControlName='description'
          class='tui-space_bottom-4 field-medium'
          [maxLength]='120'
          [expandable]='false'
        >
          Опис
        </tui-textarea>
        <tui-input-number
          class='tui-space_bottom-4'
          formControlName='order'>
          Order on view
        </tui-input-number>
        <tui-input
          class='tui-space_bottom-4'
          formControlName='type'>
          Тип учасника
        </tui-input>
        <ng-container formArrayName='links'>
          @for (link of links.controls; track link; let i = $index) {
            <div class='link-input-holder'>
              <tui-input
                class='tui-space_bottom-4'
                [formControlName]='i'>
                Лінк до соціальних мереж
              </tui-input>
              <tui-icon class='icon-button tui-space_bottom-4' icon='@tui.trash-2'
                        (click)='form.removeLink(i)'></tui-icon>
            </div>
          }
        </ng-container>
        <button class='add-link-btn' tuiButton type='button' appearance='secondary-destructive' size='s'
                (click)='form.addLink()'>
          <tui-icon icon='@tui.plus'></tui-icon>
          Add link
        </button>
        <div class='modal-footer'>
          <button
            tuiButton
            type='button'
            appearance='outline'
            (click)='onCancel()'
          >
            Cancel
          </button>
          <button
            tuiButton
            type='button'
            (click)='onSubmit()'
            [disabled]='form.invalid'
          >
            Save
          </button>
        </div>
      </div>
    }
  </div>
</tui-loader>
