<div class='container'>
  <app-page-header
    [title]="'company#list#title' | translate"
    [description]="'company#list#description' | translate"
    [actionTitle]="'company#list#add_btn' | translate"
    (action)="router.navigate(['companies/create'])"
  ></app-page-header>

  <tui-loader
    [overlay]='true'
    [showLoader]='showLoader()'
  >
  </tui-loader>
  <div class='organizations-list row'>
    @for (company of companies(); track company.id) {
      <div class='col-xs-12 col-sm-6'>
        <app-company-card
          [company]='company'
          (selectCompany)='selectCompany($event)'
          class='tui-space_bottom-5'>
        </app-company-card>
      </div>
    }
  </div>
</div>
