import { FormControl, FormGroup } from '@angular/forms';
import { constructFormGroupByData, FormGroupDef } from '@core/utils';
import { UpdateEventPartnerDto } from '@core/api/models/update-event-partner-dto';

export class EditEventPartnerForm extends FormGroup<
  FormGroupDef<Partial<UpdateEventPartnerDto>>
> {
  constructor(data: Partial<UpdateEventPartnerDto>) {
    super(constructFormGroupByData(data));
  }
}
