import { CHAR_PLUS, TUI_VERSION } from '@taiga-ui/cdk/constants';
import { tuiIsIos } from '@taiga-ui/cdk/utils/browser';
import { tuiDropdownOptionsProvider } from '@taiga-ui/core/directives/dropdown';

/**
 * @deprecated: drop in v5.0
 */
function tuiCapitalizeFirstLetter(value) {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}

/**
 * @deprecated: drop in v5.0
 */
const TUI_DATE_MODE_MASKITO_ADAPTER = {
  DMY: 'dd/mm/yyyy',
  MDY: 'mm/dd/yyyy',
  YMD: 'yyyy/mm/dd'
};

/**
 * @deprecated: drop in v5.0
 * Use {@link https://maskito.dev/core-concepts/transformer maskitoTransform} instead!
 * @example
 * import {maskitoTransform} from '@maskito/core';
 *
 * const mask = {
 *     mask: ['+', '1', ' ', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
 * };
 * maskitoTransform('12125552368', mask); // +1 (212) 555-2368
 * ___
 * @example
 * import {maskitoTransform} from '@maskito/core';
 * import {maskitoPhoneOptionsGenerator} from '@maskito/phone';
 * import metadata from 'libphonenumber-js/min/metadata';
 *
 * const options = maskitoPhoneOptionsGenerator({countryIsoCode: 'US', metadata});
 *
 * maskitoTransform('12125552368', mask); // +1 (212) 555-2368
 * ___
 * @deprecated Use {@link https://maskito.dev/core-concepts/transformer maskitoTransform} instead<br/>
 * Formats a string with the phone format +7XXXXXXXXXXXX or XXXXXXXXXXXX,
 * adding parentheses and hyphens.
 *
 * @param value the input string
 * @param countryCode a country code
 * @param phoneMask a phone number mask
 * @return the formatted phone string of the form +7 XXX XXX-XX-XX
 */
function tuiFormatPhone(value, countryCode, phoneMask) {
  if (!value) {
    return '';
  }
  let result = countryCode;
  countryCode = countryCode.replaceAll(/[()]/g, '');
  if (!value.startsWith(countryCode)) {
    value = countryCode + value.replace(CHAR_PLUS, '');
  }
  const splitPhoneMask = phoneMask.split('');
  const splitValue = value.slice(countryCode.length).split('');
  result += ' ';
  if (splitValue.length === 0) {
    return result;
  }
  for (const mask of splitPhoneMask) {
    if (splitValue.length === 0) {
      break;
    }
    if (mask === '#') {
      result += splitValue[0] || '';
      splitValue.splice(0, 1);
    } else {
      result += mask;
    }
  }
  return result;
}
const ITEM_SIZE = 1.5;
const CONTENT_SIZE = 2.5;
/**
 * @deprecated: drop in v5.0
 */
function tuiGetBorder(hasIcon, hasCleaner = false, hasTooltip = false, hasContent = false, size = 'm') {
  const offset = size === 's' ? 0 : 0.25;
  let border = 0;
  if (hasIcon) {
    border += ITEM_SIZE + offset;
  }
  if (hasCleaner) {
    border += ITEM_SIZE + offset;
  }
  if (hasTooltip) {
    border += ITEM_SIZE + offset;
  }
  if (hasContent) {
    border += CONTENT_SIZE + offset;
  }
  return border;
}

/**
 * @deprecated: drop in v5.0
 * use `validatePhoneNumberLength` from `libphonenumber-js` instead
 * ```
 * npm i libphonenumber-js
 * ```
 *
 * ```ts
 * import {validatePhoneNumberLength} from 'libphonenumber-js';
 * import metadata from 'libphonenumber-js/max/metadata';
 *
 * const validationError = validatePhoneNumberLength(
 *      '+7 777 777-7777',
 *      'KZ'
 * ) // undefined | 'INVALID_COUNTRY' | 'NOT_A_NUMBER' | 'TOO_SHORT' | 'TOO_LONG' | 'INVALID_LENGTH';
 *
 * validatePhoneNumberLength('+7777777', 'KZ'); // 'TOO_SHORT'
 * validatePhoneNumberLength('+7 777 777-7777', 'KZ'); // undefined
 * validatePhoneNumberLength('+77777777777', 'KZ'); // undefined
 * validatePhoneNumberLength('+777777777777777777', 'KZ'); // 'TOO_LONG'
 * ```
 */
function tuiGetMaxAllowedPhoneLength(countries, isoCode) {
  return countries[isoCode].replaceAll(/[()\- ]/g, '').length;
}

/**
 * @deprecated: drop in v5.0
 * Calculates size of safe area on mobile devices (status bars)
 *
 * ATTENTION: Only use in browser environment
 */
function tuiGetSafeAreaSize() {
  if (!CSS.supports('padding-top: env(safe-area-inset-top)')) {
    return 0;
  }
  const div = document.createElement('div');
  div.style.paddingTop = 'env(safe-area-inset-top)';
  document.body.appendChild(div);
  const safeAreaSize = parseInt(window.getComputedStyle(div).paddingTop, 10) || 0;
  document.body.removeChild(div);
  return safeAreaSize;
}

/**
 * @deprecated: drop in v5.0
 */
const TUI_CACHE_BUSTING_PAYLOAD = `?v=${TUI_VERSION}`;
/**
 * @deprecated: drop in v5.0
 */
const DEFAULT_ICONS_PATH = name => name.includes('.svg#') ? name : `#${name}`;
/**
 * @deprecated: drop in v5.0
 */
function tuiIconsPathFactory(staticPath) {
  const base = staticPath.endsWith('/') ? staticPath : `${staticPath}/`;
  return name => {
    if (name.startsWith('tuiIcon')) {
      return `${base}${name}.svg${TUI_CACHE_BUSTING_PAYLOAD}#${name}`;
    }
    return DEFAULT_ICONS_PATH(name);
  };
}
const SAFARI_REG_EXP = /^((?!chrome|android).)*safari/i;
/**
 * @deprecated: drop in v5.0
 */
function tuiIsApple(navigator) {
  return tuiIsIos(navigator) || SAFARI_REG_EXP.test(navigator.userAgent.toLowerCase());
}

/**
 * @deprecated: drop in v5.0
 */
function tuiIsPresumedHTMLString(candidate) {
  const trimmed = candidate.trim();
  return trimmed.startsWith('<') && trimmed.endsWith('>');
}
const MASK_AFTER_CODE_REGEXP = /\(#+\)|[#\- ]/g;
/**
 * @deprecated: drop in v5.0
 * Use `getCountryCallingCode` from `libphonenumber-js/core` instead
 * ```ts
 * import {getCountryCallingCode} from 'libphonenumber-js/core';
 * import metadata from 'libphonenumber-js/max/metadata';
 *
 * getCountryCallingCode('US', metadata) // 1
 * getCountryCallingCode('GB', metadata) // 44
 * ```
 */
function tuiIsoToCountryCode(countriesMasks, isoCode) {
  return countriesMasks[isoCode].replaceAll(MASK_AFTER_CODE_REGEXP, '');
}

/**
 * @deprecated: drop in v5.0
 * use `maskitoGetCountryFromNumber` from `@maskito/phone` instead
 * ```
 * npm i @maskito/phone
 * ```
 *
 * ```ts
 * import {maskitoGetCountryFromNumber} from '@maskito/phone';
 * import metadata from 'libphonenumber-js/max/metadata';
 *
 * const countryIsoCode = maskitoGetCountryFromNumber(
 *      '+7 777 777-7777',
 *      metadata
 * );
 *
 * countryIsoCode !== 'KZ'
 * ```
 */
function tuiNotKzRegion(value) {
  const region = Number(value.slice(1, 4));
  return region < 600 || region > 799;
}
const WIDTH_SEARCH = 'width="';
const HEIGHT_SEARCH = 'height="';
const START = '<svg';
/**
 * @deprecated: drop in v5.0
 */
function tuiProcessIcon(source, name) {
  if (source.includes(`id="${name}"`)) {
    return source;
  }
  const src = source.slice(Math.max(0, source.indexOf(START)));
  const attributes = src.slice(0, Math.max(0, src.indexOf('>')));
  if (!attributes?.includes(WIDTH_SEARCH) || !attributes.includes(HEIGHT_SEARCH)) {
    return `${src.replace(START, `<svg xmlns="http://www.w3.org/2000/svg"><g id="${name}" xmlns="http://www.w3.org/2000/svg"><svg`)}</g></svg>`;
  }
  const indexOfWidth = attributes.indexOf(WIDTH_SEARCH);
  const indexOfHeight = attributes.indexOf(HEIGHT_SEARCH);
  const widthOffset = indexOfWidth + WIDTH_SEARCH.length;
  const heightOffset = indexOfHeight + HEIGHT_SEARCH.length;
  const widthString = attributes.slice(widthOffset, attributes.indexOf('"', widthOffset));
  const heightString = attributes.slice(heightOffset, attributes.indexOf('"', heightOffset));
  if (!heightString || !widthString || widthString.includes('%') || heightString.includes('%') || widthString.includes('em') || heightString.includes('em')) {
    return src.replace(START, `<svg id="${name}"`);
  }
  const width = parseInt(widthString, 10);
  const height = parseInt(heightString, 10);
  return `<g id="${name}" xmlns="http://www.w3.org/2000/svg"><svg  x="50%" y="50%" width="${width / 16}em" height="${height / 16}em" overflow="visible" viewBox="0 0 ${width} ${height}"><svg x="${-width / 2}" y="${-height / 2}">${src}</svg></svg></g>`;
}

/**
 * @deprecated: drop in v5.0
 */
const FIXED_DROPDOWN_CONTROLLER_PROVIDER = tuiDropdownOptionsProvider({
  limitWidth: 'fixed',
  align: 'right'
});

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_ICONS_PATH, FIXED_DROPDOWN_CONTROLLER_PROVIDER, TUI_CACHE_BUSTING_PAYLOAD, TUI_DATE_MODE_MASKITO_ADAPTER, tuiCapitalizeFirstLetter, tuiFormatPhone, tuiGetBorder, tuiGetMaxAllowedPhoneLength, tuiGetSafeAreaSize, tuiIconsPathFactory, tuiIsApple, tuiIsPresumedHTMLString, tuiIsoToCountryCode, tuiNotKzRegion, tuiProcessIcon };
