<div class='company-card' (click)='selectCompany?.emit(company)'>
  <div class='card-content'>
    <div class='top-info'>
      <tui-avatar size="xl" src='{{(company?.logo_url | imageUrl)}}'></tui-avatar>
    </div>
    <div class='org-info'>
      <h2 class='tui-text_h2'>{{company?.name}}</h2>
      <span>{{company?.specialization}}</span>
    </div>
  </div>
</div>
