import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { Observable } from 'rxjs';
import { EventEntity } from '@core/api/models/event-entity';
import { EventService } from '@core/api/services';
import { finalize, map } from 'rxjs/operators';
import { environment } from 'client/src/environments/environment';
import { TuiButton, TuiLoader } from '@taiga-ui/core';
import { AsyncPipe, DatePipe, NgStyle } from '@angular/common';
import { ImageUrlPipe } from '@core/pipes/image-url.pipe';
import { TuiTagModule } from '@taiga-ui/legacy';
import { TuiTab, TuiTabsHorizontal } from '@taiga-ui/kit';
import { GeneralEventInfoComponent } from '../../components/general-event-info/general-event-info.component';
import { EventProgramListComponent } from '../../components/event-program-list/event-program-list.component';
import { EventArtistsListComponent } from '../../components/event-artists-list/event-artists-list.component';
import { EventPartnerListComponent } from '../../components/event-partner-list/event-partner-list.component';
import { EventTicketTiersListComponent } from '../../components/event-ticket-tiers-list/event-ticket-tiers-list.component';

@Component({
  selector: 'event-detail-page',
  templateUrl: './event-detail.page.html',
  styleUrls: ['./event-detail.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiLoader,
    AsyncPipe,
    NgStyle,
    ImageUrlPipe,
    DatePipe,
    TuiTagModule,
    TuiTabsHorizontal,
    TuiTab,
    GeneralEventInfoComponent,
    EventProgramListComponent,
    EventArtistsListComponent,
    EventPartnerListComponent,
    EventTicketTiersListComponent,
    TuiButton
],
  standalone: true,
})
export class EventDetailPage extends BaseAbstractPage implements OnInit {
  activeItemIndex = 0;
  public eventService = inject(EventService);
  public event$: Observable<EventEntity | null>;

  ngOnInit(): void {
    this.showLoader.set(true);
    this.loadEvent();
  }

  loadEvent() {
    this.event$ = this.eventService
      .eventControllerFindOne({ event_id: this.route.snapshot.params.id })
      .pipe(
        finalize(() => this.showLoader.set(false)),
        map((res) => res.data || null)
      );
  }

  showEvent() {
    window.open(
      `${environment.apiConfigs.WEB_CLIENT_URL}/event/${this.route.snapshot.params.id}`,
      '_blank'
    );
  }
}
