import { ErrorHandler, inject, Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { AuthCompanyService } from '@core/services/auth-company.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private injector: Injector = inject(Injector);
  private authService = inject(AuthService);
  private authCompanyService = inject(AuthCompanyService);
  private router: Router = inject(Router);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: async (err: any) => {
          if (err instanceof HttpErrorResponse) {
            const appErrorHandler = this.injector.get(ErrorHandler);

            //ToDO reset token
            if (err.status === 401) {
              this.authCompanyService.reset()
              await this.router.navigateByUrl('auth');
            }
            appErrorHandler.handleError(err);
          }
        }
      })
    );
  }
}
