/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { companyControllerCreate } from '../fn/company/company-controller-create';
import { CompanyControllerCreate$Params } from '../fn/company/company-controller-create';
import { companyControllerFindAll } from '../fn/company/company-controller-find-all';
import { CompanyControllerFindAll$Params } from '../fn/company/company-controller-find-all';
import { companyControllerFindById } from '../fn/company/company-controller-find-by-id';
import { CompanyControllerFindById$Params } from '../fn/company/company-controller-find-by-id';
import { companyControllerUpdate } from '../fn/company/company-controller-update';
import { CompanyControllerUpdate$Params } from '../fn/company/company-controller-update';
import { companyControllerUpdateLogo } from '../fn/company/company-controller-update-logo';
import { CompanyControllerUpdateLogo$Params } from '../fn/company/company-controller-update-logo';
import { CompanyEntity } from '../models/company-entity';

@Injectable({ providedIn: 'root' })
export class CompanyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `companyControllerFindAll()` */
  static readonly CompanyControllerFindAllPath = '/api/v1/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyControllerFindAll$Response(
    params: CompanyControllerFindAll$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: {
        total?: number;
        limit?: number;
        page?: number;
        items?: Array<CompanyEntity>;
      };
      message?: string;
    }>
  > {
    return companyControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyControllerFindAll(
    params: CompanyControllerFindAll$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<CompanyEntity>;
    };
    message?: string;
  }> {
    return this.companyControllerFindAll$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: {
              total?: number;
              limit?: number;
              page?: number;
              items?: Array<CompanyEntity>;
            };
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: {
            total?: number;
            limit?: number;
            page?: number;
            items?: Array<CompanyEntity>;
          };
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `companyControllerCreate()` */
  static readonly CompanyControllerCreatePath = '/api/v1/companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companyControllerCreate$Response(
    params: CompanyControllerCreate$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: CompanyEntity;
      message?: string;
    }>
  > {
    return companyControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companyControllerCreate(
    params: CompanyControllerCreate$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: CompanyEntity;
    message?: string;
  }> {
    return this.companyControllerCreate$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: CompanyEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: CompanyEntity;
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `companyControllerFindById()` */
  static readonly CompanyControllerFindByIdPath = '/api/v1/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyControllerFindById$Response(
    params: CompanyControllerFindById$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: CompanyEntity;
      message?: string;
    }>
  > {
    return companyControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyControllerFindById(
    params: CompanyControllerFindById$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: CompanyEntity;
    message?: string;
  }> {
    return this.companyControllerFindById$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: CompanyEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: CompanyEntity;
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `companyControllerUpdate()` */
  static readonly CompanyControllerUpdatePath = '/api/v1/companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companyControllerUpdate$Response(
    params: CompanyControllerUpdate$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: CompanyEntity;
      message?: string;
    }>
  > {
    return companyControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companyControllerUpdate(
    params: CompanyControllerUpdate$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: CompanyEntity;
    message?: string;
  }> {
    return this.companyControllerUpdate$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: CompanyEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: CompanyEntity;
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `companyControllerUpdateLogo()` */
  static readonly CompanyControllerUpdateLogoPath =
    '/api/v1/companies/{id}/logo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyControllerUpdateLogo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  companyControllerUpdateLogo$Response(
    params: CompanyControllerUpdateLogo$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: CompanyEntity;
      message?: string;
    }>
  > {
    return companyControllerUpdateLogo(
      this.http,
      this.rootUrl,
      params,
      context
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyControllerUpdateLogo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  companyControllerUpdateLogo(
    params: CompanyControllerUpdateLogo$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: CompanyEntity;
    message?: string;
  }> {
    return this.companyControllerUpdateLogo$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: CompanyEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: CompanyEntity;
          message?: string;
        } => r.body
      )
    );
  }
}
