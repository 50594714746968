<tui-loader [overlay]='true' [showLoader]='this.showLoader()'>
  @if (eventTicketTiers$ | async; as tickets) {
    <div class="tui-space_bottom-8">
      <div tuiHeader="xl">
        <h2 tuiTitle>
          <span>Блок програма</span>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
        </h2>

        <div tuiAccessories>
          <button
            tuiButton
            type='button'
            size='m'
            (click)='add()'
          >
            <tui-icon icon='@tui.plus'></tui-icon>
            Add new
          </button>
        </div>
      </div>
    </div>
    @if (tickets?.data.items.length !== 0) {
      <div tuiCardLarge tuiSurface="elevated">
        <table
          tuiTable
          class='table'
          size="l"
          [style.width.%]="100"
          [columns]='displayedColumns'
        >
          <thead>
          <tr>
            <th
              tuiTh
              [resizable]='false'
            >
              <div tuiCell="l">
                Назва квитка
              </div>
            </th>
            <th tuiTh>
              <div tuiCell="l">
                Вартість квитка
              </div>
            </th>
            <th tuiTh>
              <div tuiCell="l">
                Кількість
              </div>
            </th>
            <th tuiTh>
              <div tuiCell="l">
                Опис
              </div>
            </th>
            <th tuiTh>
              <div tuiCell="l">
                Діючий
              </div>
            </th>
            <th tuiTh>
              <div tuiCell="l">
                Actions
              </div>
            </th>
          </tr>
          </thead>
          <tbody
            tuiTbody
            [data]='tickets.data.items'
          >
            @for (item of tickets.data.items; track item) {
              <tr
                tuiTr
              >
                <td
                  *tuiCell="'info'"
                  tuiTd
                >
                  <div class='img-with-title'>
                    <!--              <div class='avatar'><img [src]='item.img_url' [alt]='item.name' (error)='onImgError($event)'></div>-->
                    {{ item.name || '-' }}
                  </div>
                </td>
                <td
                  *tuiCell="'price'"
                  tuiTd
                >
                  {{ item.price || '-' }} {{ item.currency | tuiCurrency }}
                </td>
                <td
                  *tuiCell="'qty'"
                  tuiTd
                >
                  {{ item.total_quantity || '-' }}
                </td>
                <td
                  *tuiCell="'desc'"
                  tuiTd
                >
                  @if (item.description) {
                    {{ item.description | slice : 0 : 30 }}...
                  } @else {
                    -
                  }
                </td>
                <td
                  *tuiCell="'active'"
                  tuiTd
                >
                  {{ item.active || '-' }}
                </td>
                <td
                  class='text-right'
                  *tuiCell="'actions'"
                  tuiTd
                >
                  <tui-icon class='icon-button' (click)='add()' icon="@tui.pencil"></tui-icon>
                </td>
              </tr>
            }
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>
    }
    @if (tickets?.data.items.length === 0) {
      <app-empty-section-with-action
        [title]="'Ви ще не додавали квитки'"
        [description]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'"
        [iconName]="'@tui.users'"
        [actionTitle]="'Додати квиток'"
        (action)='add()'
      ></app-empty-section-with-action>
    }
  }
</tui-loader>
