import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupDef } from '@core/utils';
import { CreateTierDto } from '@core/api/models/create-tier-dto';
import { TuiDay, TuiTime } from '@taiga-ui/cdk';

export interface CreateTierForm extends CreateTierDto {
  end_date: TuiDay | null;
  end_time: TuiTime | null;
}

export class AddEventTicketTierForm extends FormGroup<
  FormGroupDef<Partial<CreateTierForm>>
> {
  constructor() {
    const date = new Date();
    super({
      name: new FormControl('', { nonNullable: true }),
      description: new FormControl('', { nonNullable: true }),
      currency: new FormControl('', { nonNullable: true }),
      price: new FormControl(0, { nonNullable: true }),
      total_quantity: new FormControl(0, { nonNullable: true }),
      termless: new FormControl(true, { nonNullable: true }),
      end_date: new FormControl(
        new TuiDay(date.getFullYear(), date.getMonth(), date.getDay())
      ),
      end_time: new FormControl(
        new TuiTime(date.getHours(), date.getMinutes())
      ),
      active: new FormControl(true, { nonNullable: true })
    });

    this.controls.name?.addValidators([Validators.required]);
    this.controls.description?.addValidators([Validators.required]);
    this.controls.currency?.addValidators([Validators.required]);
    this.controls.price?.addValidators([Validators.required]);
    this.controls.total_quantity?.addValidators([Validators.required]);
    this.controls.termless?.addValidators([Validators.required]);
  }

  get endDate() {
    return this.controls['end_date'] as FormControl;
  }

  get endTime() {
    return this.controls['end_time'] as FormControl;
  }
}
