<app-page-header
  [title]="'events#list#title' | translate"
  [description]="'events#list#description' | translate"
  [actionTitle]="'events#list#add_btn' | translate"
  (action)="router.navigate(['events/create'])"
></app-page-header>

<tui-loader
  [overlay]='true'
  [showLoader]='showLoader()'
  >

  <h2 class='tui-text_h2 tui-space_bottom-4'>{{ 'events#list#sub_title' | translate }}</h2>
  <div class='events-list'>
    @for (event of data(); track event) {
      <div class='col'>
        <event-card-component (eventClick)='viewEvent(event.id)' [event]='event'></event-card-component>
      </div>
    }
  </div>
</tui-loader>
