<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div class="container">
    <div class="row center-xs">
      <div class="col-xs-12 col-md-12 tui-space_bottom-3">
        <tui-island class="with-bg" style="background-image: url('assets/auth-bg-2.jpg')">
          <div class="logo-holder">
            <img src="assets/password.svg">
          </div>
        </tui-island>
      </div>
      <div class="col-xs-12 col-md-12">
        <tui-island>
          <h1 class="tui-space_bottom-2 center-xs">{{ 'Сброс паролю' | translate }}</h1>
          <p class="tui-space_bottom-10 center-xs">Ми відправили лист на вашу почту j....&#64;gmail.com. Дотримуйтесь
            вказівок у листі..</p>

          <button
            tuiButton
            type="button"
            appearance="primary"
            (click)="resendResetPassword()"
            class="sign-in-btn"
          >
            Resend reset password
          </button>
        </tui-island>
      </div>
    </div>
  </div>
</tui-loader>
