import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { of, switchMap, takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TuiFiles, } from '@taiga-ui/kit';
import { CompanyService } from '@core/api/services';
import { TuiInputModule } from '@taiga-ui/legacy';
import { TuiButton, TuiIcon, TuiLoader, TuiSurface } from '@taiga-ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { TuiCardLarge } from '@taiga-ui/layout';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';

@Component({
  selector: 'company-create-page',
  templateUrl: './company-create.page.html',
  styleUrls: ['./company-create.page.scss'],
  imports: [
    ReactiveFormsModule,
    TuiButton,
    TuiIcon,
    TranslateModule,
    AsyncPipe,
    TuiInputModule,
    TuiFiles,
    TuiCardLarge,
    TuiSurface,
    ImagePickerComponent,
    TuiLoader
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyCreatePage extends BaseAbstractPage implements OnInit {
  public createForm: FormGroup;
  private formBuilder = inject(FormBuilder);
  private companiesService = inject(CompanyService);

  public image: WritableSignal<any> = signal(null);

  ngOnInit(): void {
    this.createForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      specialization: ['', [Validators.required]]
    });
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get emailFormControl() {
    return this.createForm.get('email') as FormControl;
  }

  get nameFormControl() {
    return this.createForm.get('name') as FormControl;
  }

  get specializationFormControl() {
    return this.createForm.get('specialization') as FormControl;
  }

  pickImage(image: any) {
    this.image.set(image);
  }

  async createCompany() {
    this.showLoader.set(true);
    this.companiesService
      .companyControllerCreate({
        body: {
          email: this.emailFormControl.value,
          name: this.nameFormControl.value,
          specialization: this.specializationFormControl.value
        }
      })
      .pipe(
        switchMap((res) =>
          res.state && res?.data?.id
            ? this.companiesService.companyControllerUpdateLogo({
                id: res.data.id,
                body: { image: this.image() ? this.image() : undefined }
              })
            : of(res)
        ),
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false))
      )
      .subscribe({
        next: async (res: any) => {
          await this.router.navigateByUrl('/companies');
        },
        error: async (error) => {
          const err = error.error ? JSON.parse(error.error) : error;
          this.notificationService
            .open(err.message, {
              label: 'With a heading!',
              appearance: 'warning'
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        }
      });
  }
}
