<div tuiCardLarge tuiSurface="elevated">
  @if (createForm) {
    <div [formGroup]='createForm'>
      <h1 class='tui-text_h1'>{{ 'events#create#title' | translate }}</h1>
      <hr>
      <div class='row'>
        <div class='col-md-6'>
          <h2 class='tui-text_h2 tui-space_bottom-4'>{{ 'events#create#lgeneral' | translate }}</h2>
          <p>{{ 'events#create#general_description' | translate }}</p>
        </div>
        <div class='col-md-6'>
          <tui-input
            class='tui-space_vertical-4'
            formControlName='title'>
            {{ 'events#create#name' | translate }}
            <input
              tuiTextfieldLegacy
              type='text'
            />
          </tui-input>
          <app-image-picker
            (selectImage)="pickImage($event)"
          ></app-image-picker>
        </div>
      </div>
      <div class="row">
        <div class='col-md-12'>
          <hr>
        </div>
        <div class='col-md-6'>
          <h2 class='tui-text_h2 tui-space_bottom-4'>{{ 'events#create#configure' | translate }}</h2>
          <p> {{ 'events#create#configure_description' | translate }}</p>
        </div>
        <div class='col-md-6'>
          <tui-select
            tuiTextfieldSize='l'
            class='tui-space_vertical-4'
            formControlName='type'
            [valueContent]="selectItemType"
          >
            {{ 'events#create#type' | translate }}
            <tui-data-list-wrapper
              *tuiDataList
              [items]='eventTypes'
              [itemContent]="selectItemType"
            ></tui-data-list-wrapper>
            <ng-template
              #selectItemType
              let-item
            >
              <span>{{ ('events#create#type_' + item) | translate }}</span>
            </ng-template>
          </tui-select>

          <tui-select
            tuiTextfieldSize='l'
            class='tui-space_vertical-4'
            formControlName='status'
            [valueContent]="selectItemStatus"
          >
            {{ 'events#create#status' | translate }}
            <tui-data-list-wrapper
              *tuiDataList
              [items]='eventStatusTypes'
              [itemContent]="selectItemStatus"
            ></tui-data-list-wrapper>
            <ng-template
              #selectItemStatus
              let-item
            >
              <span>{{ ('events#create#status_' + item) | translate }}</span>
            </ng-template>
          </tui-select>

          <tui-select
            tuiTextfieldSize='l'
            class='tui-space_vertical-4'
            formControlName='smart_tickets'
            [valueContent]="selectItemSmart"
          >
            {{ 'events#create#smart' | translate }}
            <tui-data-list-wrapper
              *tuiDataList
              [items]='smartTicketsTypes'
              [itemContent]="selectItemSmart"

            ></tui-data-list-wrapper>
            <ng-template
              #selectItemSmart
              let-item
            >
              <span>{{ ('events#create#smart_' + item) | translate }}</span>
            </ng-template>
          </tui-select>

          <tui-input-number
            class='tui-space_vertical-4'
            formControlName='age_rating'
            min="0"
            max="100"
          >
            {{ 'events#create#age' | translate }}
          </tui-input-number>

        </div>
      </div>

      <div class="row">
        <div class='col-md-12'>
          <hr>
        </div>
        <div class='col-md-6'>
          <h2 class='tui-text_h2 tui-space_bottom-4'>{{ 'events#create#date_time' | translate }}</h2>
          <p>{{ 'events#create#date_time_description' | translate }}</p>
        </div>
        <div class='col-md-6'>
          <tui-input-date-range
            class='tui-space_vertical-4'
            tuiUnfinishedValidator='Finish filling the field'
            formControlName='dateRangeControl'
            [min]='min'
            [max]='max'
          >
            {{ 'events#create#choose_dates' | translate }}
            <input
              tuiTextfieldLegacy
              placeholder='From - To'
            />
          </tui-input-date-range>

          <div class="row">
            <div class='col-xs-6'>
              <tui-input-time
                class='tui-space_vertical-4'
                formControlName='startTime'
                [tuiTextfieldCleaner]='true'
                [items]='timePeriod'
              >
                {{ 'events#create#start_time' | translate }}
              </tui-input-time>
            </div>
            <div class='col-xs-6'>
              <tui-input-time
                class='tui-space_vertical-4'
                formControlName='endTime'
                [tuiTextfieldCleaner]='true'
                [items]='timePeriod'
              >
                {{ 'events#create#end_time' | translate }}
              </tui-input-time>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class='col-xs-12'>
          <label tuiLabel>
            <input
              tuiCheckbox type="checkbox"
              class='tui-space_vertical-4'
              formControlName='draft'
              size="m"
            >
            {{ 'events#create#draft' | translate }}
          </label>
        </div>
        <div class='col-md-12'>
          <hr>
        </div>
        <div class='col-md-6'>
          <button
            tuiButton
            appearance='secondary'
            type='button'
            size='l'
            class='tui-space_vertical-4'
            (click)='back()'
          >
            {{ 'common#cancel' | translate }}
          </button>
        </div>
        <div class='col-md-6 end-xs'>
          <button
            tuiButton
            type='button'
            size='l'
            class='tui-space_vertical-4'
            (click)='create()'
            [disabled]='createForm.invalid'
          >
            {{ 'common#create' | translate }}
          </button>
        </div>
      </div>
    </div>
  }
</div>



