import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom
} from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { HttpErrorInterceptor } from '@core/http-interceptors/http-error.interceptor';
import { AuthInterceptor } from '@core/http-interceptors/auth.interceptor';
import { CompanyInterceptor } from '@core/http-interceptors/company.interceptor';
import { AppErrorHandler } from '@core/error-handler/app-error-handler.service';
import { ApiModule } from '@core/api/api.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app/app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAnimations } from '@angular/platform-browser/animations';
import SuperTokens from 'supertokens-web-js/lib/build/supertokens';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Multitenancy from 'supertokens-web-js/recipe/multitenancy';
import ThirdParty from 'supertokens-web-js/recipe/thirdparty';
import { AuthService } from '@core/services/auth.service';
import { AuthCompanyService } from '@core/services/auth-company.service';
import { ThemeService } from '@core/services/theme.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

if (environment.production) {
  enableProdMode();
}

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.i18nPrefix}/assets/i18n/`,
    '.json'
  );
}

SuperTokens.init({
  appInfo: environment.auth.appInfo,
  recipeList: [
    Session.init(),
    EmailPassword.init(),
    ThirdParty.init(),
    Multitenancy.init({
      override: {
        functions: (oI) => {
          return {
            ...oI,
            getTenantId: (input) => {
              return environment.auth.tenant;
            }
          };
        }
      }
    })
  ]
});

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    LocalStorageService,
    AuthService,
    AuthCompanyService,
    ThemeService,
    importProvidersFrom(
      ApiModule.forRoot({ rootUrl: environment.apiConfigs.API_URL }),
      FormsModule,
      RouterModule,
      ReactiveFormsModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CompanyInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    provideHttpClient(withInterceptorsFromDi())
  ]
});
