<tui-loader [overlay]='true' [showLoader]='showLoader()'>
  @if (event$ | async; as event) {
    <div>
      <div class='event-header'>
        <div class='event-header_background'
          [ngStyle]="{'background-image': 'url(' + (event?.general?.main_img | imageUrl) + ')'}">
          <div class='event-header_background_blur'></div>
        </div>
        <div class='event-header_content'>
          <div class='img-holder'>
            <img [src]='event.general?.main_img | imageUrl' alt=''>
          </div>
          <div class='event-header_content_info'>
            <div class='row'>
              <div class='col-md-6'>
                <h2 class='tui-text_h1 tui-space_bottom-1'>{{ event.general?.title }}</h2>
                <div class='event-header_content_info_sub'>
                  <div class='date'>{{ event.start_date_time | date }} - {{ event.end_date_time | date }}</div>
                  <tui-tag
                    size='l'
                    status='custom'
                    class='tag'
                    [value]='event.status'
                  ></tui-tag>
                </div>
              </div>
              <div class='col-md-6'>
                <div class='event-header_content_info_btns'>
                  <button tuiButton
                    type='button'
                    appearance='secondary'
                    class='tui-space_left-4'
                    size='l'
                    (click)='showEvent()'
                    >Show event
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <tui-tabs class='tabs tui-space_bottom-10' [(activeItemIndex)]='activeItemIndex'>
        <button
          tuiTab
          >
          Основна інформація
        </button>
        <button
          tuiTab
          >
          Програма
        </button>
        <button
          tuiTab
          >
          Учасники
        </button>
        <button
          tuiTab
          >
          Партнери
        </button>
        <button
          tuiTab
          >
          Квитки
        </button>
      </tui-tabs>
      <div class="tabs-holder">
        @if (activeItemIndex === 0) {
          <general-event-info-component [eventId]='event.id'
                                        (updateEventInfo)='loadEvent()'></general-event-info-component>
        }
        @if (activeItemIndex === 1) {
          <event-program-list-component [eventId]='event.id'></event-program-list-component>
        }
        @if (activeItemIndex === 2) {
          <event-artists-list-component [eventId]='event.id'></event-artists-list-component>
        }
        @if (activeItemIndex === 3) {
          <event-partner-list-component [eventId]='event.id'></event-partner-list-component>
        }
        @if (activeItemIndex === 4) {
          <event-ticket-tiers-list-component
            [eventId]='event.id'></event-ticket-tiers-list-component>
        }
      </div>
    </div>
  }
</tui-loader>
