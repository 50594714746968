import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { TuiButton, TuiLink, TuiLoader } from '@taiga-ui/core';
import {
  TuiInputModule,
  TuiInputPasswordModule,
  TuiIslandDirective
} from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'invite-member-page',
  templateUrl: './invite-member.page.html',
  styleUrls: ['./invite-member.page.scss'],
  imports: [
    TuiLoader,
    ReactiveFormsModule,
    TuiIslandDirective,
    TranslateModule,
    TuiInputModule,
    TuiInputPasswordModule,
    TuiButton,
    TuiLink,
    RouterLink
  ],
  standalone: true
})
export class InviteMemberPage extends BaseAbstractPage implements OnInit {
  public params: WritableSignal<{
    companyId: string;
    companyName: string;
    email: string;
    inviteId: string;
  } | null> = signal(null);
  private formBuilder = inject(FormBuilder);
  public signUpForm: FormGroup;

  ngOnInit(): void {
    if (this.route.snapshot.queryParams) {
      this.params.set({
        companyId: this.route.snapshot.queryParams?.companyId,
        companyName: this.route.snapshot.queryParams?.companyName,
        email: this.route.snapshot.queryParams?.email,
        inviteId: this.route.snapshot.queryParams?.inviteId
      });
    }
    this.signUpForm = this.formBuilder.group({
      email: [
        { value: this.params()?.email || '', disabled: true },
        [Validators.required, Validators.email]
      ],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get emailFormControl() {
    return this.signUpForm.get('email') as FormControl;
  }

  get passwordFormControl() {
    return this.signUpForm.get('password') as FormControl;
  }

  get confirmPasswordFormControl() {
    return this.signUpForm.get('confirmPassword') as FormControl;
  }

  public async authorize() {
    this.showLoader.set(true);

    if (!this.params()) {
      this.showLoader.set(false);
      return;
    }

    const { inviteId, companyId } = this.params()!;

    // this.authService
    //   .authControllerSignUpCompanyMember({
    //     body: {
    //       email: this.emailFormControl.value,
    //       password: this.passwordFormControl.value,
    //       inviteId: inviteId,
    //       companyId: companyId
    //     }
    //   })
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     finalize(() => this.showLoader.set(false))
    //   )
    //   .subscribe({
    //     next: async (res: any) => {
    //       const result = JSON.parse(res);
    //       this.store.dispatch(actionSignIn(result));
    //       await this.router.navigateByUrl('/companies');
    //     },
    //     error: (error) =>
    //       this.notificationService
    //         .open(error.message, {
    //           label: 'With a heading!',
    //           status: 'warning'
    //         })
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe()
    //   });
  }
}
