import { Routes } from '@angular/router';
import { PaymentListPage } from './pages';

export const PaymentRoute: Routes = [
  {
    path: '',
    component: PaymentListPage,
    data: { title: 'man.menu.settings' }
  }
];
