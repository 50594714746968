import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { CompanyService } from '@core/api/services';
import { CompanyEntity } from '@core/api/models/company-entity';
import { PageHeaderComponent } from '@shared/page-header/page-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLoader } from '@taiga-ui/core';
import { CompanyCardComponent } from '../../components/company-card/company-card.component';
import { AuthCompanyService } from '@core/services/auth-company.service';

@Component({
  selector: 'company-list',
  templateUrl: './company-list.page.html',
  styleUrls: ['./company-list.page.scss'],
  imports: [
    PageHeaderComponent,
    TranslateModule,
    TuiLoader,
    CompanyCardComponent
  ],
  standalone: true
})
export class CompanyListPage extends BaseAbstractPage implements OnInit {
  public companies: WritableSignal<CompanyEntity[]> = signal([]);
  private companiesService = inject(CompanyService);
  private authCompanyService = inject(AuthCompanyService);

  ngOnInit(): void {
    this.companiesService
      .companyControllerFindAll({ limit: 50, page: 1 })
      .subscribe({
        next: (res) => {
          if (res.data?.items) {
            this.companies.set(res.data.items);
          }
        }
      });
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  async selectCompany(company: CompanyEntity) {
    this.authCompanyService.set(company);
    await this.router.navigateByUrl('/events');
  }
}
