import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit
} from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { TuiButton, TuiLink, TuiLoader } from '@taiga-ui/core';
import { TuiInputPasswordModule, TuiIslandDirective } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'reset-password-page',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
  imports: [
    TuiLoader,
    ReactiveFormsModule,
    TuiIslandDirective,
    TuiInputPasswordModule,
    TranslateModule,
    TuiButton,
    TuiLink,
    RouterLink
  ],
  standalone: true
})
export class ResetPasswordPage extends BaseAbstractPage implements OnInit {
  public resetPasswordForm: FormGroup;
  private formBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmedPassword: ['', [Validators.required]]
    });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get passwordFormControl() {
    return this.resetPasswordForm.get('password') as FormControl;
  }

  get confirmedPasswordFormControl() {
    return this.resetPasswordForm.get('confirmedPassword') as FormControl;
  }

  public async resetPassword() {}
}
