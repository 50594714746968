import { Component, OnInit } from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { TuiButton, TuiLoader } from '@taiga-ui/core';
import { TuiIslandDirective } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'confirmed-reset-password-page',
  templateUrl: './confirmed-reset-password.page.html',
  styleUrls: ['./confirmed-reset-password.page.scss'],
  imports: [TuiLoader, TuiIslandDirective, TranslateModule, TuiButton],
  standalone: true
})
export class ConfirmedResetPasswordPage
  extends BaseAbstractPage
  implements OnInit
{
  ngOnInit(): void {
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  public async authorize() {}
}
