@if (!control.value) {
  <label
    tuiInputFiles
  >
    <input
      tuiInputFiles
      accept="image/*"
      [multiple]="false"
      [formControl]="control"
    />
  </label>
}

<tui-files class='tui-space_bottom-6'>

  @if (control.value | tuiFileRejected: { accept: 'image/*' } | async; as file) {
    <tui-file
      state="error"
      [file]="file"
      (remove)="removeFile()"
    />
  }

  @if (loadedFiles$ | async; as file) {
    <tui-file
      [file]='file'
      (remove)='removeFile()'
    ></tui-file>
  }

  @if (failedFiles$ | async; as file) {
    <tui-file
      state='error'
      [file]='file'
      (remove)='removeFile()'
    ></tui-file>
  }

  @if (loadingFiles$ | async; as file) {
    <tui-file
      state='loading'
      [file]='file'
      (remove)='removeFile()'
    ></tui-file>
  }
</tui-files>

@if (loadedFiles$ | async; as file) {
  @if (control?.value) {
    <div class='preview-holder'>
      <img [src]='control?.value | objectUrl' class='preview'
           alt='preview'>
    </div>
  }
}
@if (!control.value && existImageUrl) {
  <div class='preview-holder'>
    <img [src]='existImageUrl | imageUrl' class='preview' alt='preview'>
  </div>
}
