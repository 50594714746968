import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { finalize } from 'rxjs/operators';
import { takeUntil } from 'rxjs';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { EventEntity } from '@core/api/models/event-entity';
import { EventService } from '@core/api/services';
import { PageHeaderComponent } from '@shared/page-header/page-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { TuiLoader } from '@taiga-ui/core';
import { EventCardComponent } from '../../components/event-card/event-card.component';


@Component({
  selector: 'event-list-page',
  templateUrl: './event-list.page.html',
  styleUrls: ['./event-list.page.scss'],
  standalone: true,
  imports: [
    PageHeaderComponent,
    TranslateModule,
    TuiLoader,
    EventCardComponent
],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventListPage extends BaseAbstractPage implements OnInit {
  private eventService = inject(EventService);
  public data: WritableSignal<EventEntity[]> = signal([]);
  totalRows = 0;
  pageSize = 15;
  currentPage = 1;

  async ngOnInit() {
    this.fetchData();
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  fetchData() {
    this.showLoader.set(true);
    const params = {
      limit: this.pageSize,
      page: this.currentPage
    };
    this.eventService
      .eventControllerFindAllByCompanyId(params)
      .pipe(takeUntil(this.destroy$))
      .pipe(
        finalize(() => {
          this.showLoader.set(false);
        })
      )
      .subscribe({
        next: (res) => {
          if (res.state && res.data) {
            if (res.data.items) {
              this.data.set(res.data.items);
            }
            this.totalRows = res.data.total!;
          }
        },
        error: (err) => {},
        complete: () => {}
      });
  }

  public viewEvent(id: string) {
    this.router.navigateByUrl(`events/event-detail/${id}`);
  }
}
