import { Routes } from '@angular/router';
import { EventCreatePage, EventDetailPage, EventListPage } from './pages';

export const EventRoutes: Routes = [
  {
    path: '',
    component: EventListPage
  },
  {
    path: 'event-detail/:id',
    component: EventDetailPage,
    data: { breadcrumb: '...' }
  },
  // {
  //   path: 'company-edit/:id',
  //   component: EditEvent,
  //   data: { title: 'man.menu.settings' },
  // },
  {
    path: 'create',
    component: EventCreatePage,
    data: { breadcrumb: 'Create' }
  }
];
