<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div class="container" [formGroup]="loginForm">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <tui-island class="with-bg" style="background-image: url('assets/auth-bg.jpg')">
          <div class="logo-holder">
            <img src="assets/single-logo-white.svg">
          </div>
        </tui-island>
      </div>
      <div class="col-xs-12 col-md-6">
        <tui-island>
          <div class="form-holder">
            <h1 class="tui-space_bottom-2 center-xs">{{ 'auth#sign_in#title' | translate }}</h1>
            <p class="tui-space_bottom-8 center-xs">{{ 'auth#sign_in#description' | translate }}</p>
            <tui-input class="tui-space_bottom-4" formControlName="email">
              {{ 'auth#sign_in#e-mail' | translate }}
            </tui-input>
            <tui-input-password class="tui-space_bottom-4" formControlName="password">
              {{ 'auth#sign_in#password' | translate }}
            </tui-input-password>
            <!--            <a-->
            <!--              tuiLink-->
            <!--              type="button"-->
            <!--              (click)="resetPassword()"-->
            <!--              class="styled-link tui-space_bottom-3"-->
            <!--            >-->
            <!--              {{ 'auth#sign_in#forgot_password | translate }}-->
            <!--            </a>-->

            <footer>
              <button
                tuiButton
                type="button"
                appearance="primary"
                [disabled]="loginForm.invalid"
                (click)="singIn()"
                class="sign-in-btn tui-space_bottom-7"
              >
                {{ 'auth#sign_in#login' | translate }}
              </button>
              <div class="separate-line"><span>або</span></div>
              <button
                tuiButton
                type="button"
                appearance="outline"
                (click)="singInThirdParty('google')"
                class="sign-in-btn tui-space_bottom-2"
              >
                {{ 'auth#sign_in#login_via_google' | translate }}
              </button>
              <button
                tuiButton
                type="button"
                appearance="glass"
                (click)="singInThirdParty('apple')"
                class="sign-in-btn tui-space_bottom-7"
              >
                {{ 'auth#sign_in#login_via_apple' | translate }}
              </button>
              <p class="tui-island_text-align_center"
                 [innerHTML]="('auth#sign_in#not_have_account' | translate : {link: '/auth/sign-up'})">
              </p>
            </footer>
          </div>
        </tui-island>
      </div>
    </div>
  </div>
</tui-loader>
