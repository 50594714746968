<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div class="container">
    <div class="row center-xs">
      <div class="col-xs-12 col-md-12 tui-space_bottom-3">
        <tui-island class="with-bg" style="background-image: url('assets/auth-bg-4.jpg')">
          <div class="logo-holder">
            <img src="assets/confirmed.svg">
          </div>
        </tui-island>
      </div>
      <div class="col-xs-12 col-md-12">
        <tui-island>
          <h1 class="tui-space_bottom-2 center-xs">{{ 'Пошту підтверджено' | translate }}</h1>
          <p class="tui-space_bottom-10 center-xs">Ваш аккаунт зареестровано. Теперь ви можете почати користуватися
            TKTSpace.</p>
          <button
            tuiButton
            type="button"
            appearance="primary"
            (click)="authorize()"
            class="sign-in-btn"
          >
            Authorize
          </button>
        </tui-island>
      </div>
    </div>
  </div>
</tui-loader>
