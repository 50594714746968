import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupDef } from '@core/utils';
import { CreateEventPartnerDto } from '@core/api/models/create-event-partner-dto';

export class AddEventPartnerForm extends FormGroup<
  FormGroupDef<Partial<CreateEventPartnerDto>>
> {
  constructor() {
    super({
      title: new FormControl('', { nonNullable: true }),
      url: new FormControl('', { nonNullable: true }),
      order: new FormControl(0, { nonNullable: true })
    });

    this.controls.title?.addValidators([Validators.required]);
    this.controls.url?.addValidators([Validators.required]);
  }
}
