import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'member-detail-page',
  templateUrl: './member-detail.page.html',
  styleUrls: ['./member-detail.page.scss'],
  standalone: true
})
export class MemberDetailPage implements OnInit {
  ngOnInit(): void {}
}
