<tui-loader
  [overlay]='true'
  [showLoader]='showLoader()'
>
  <div class='modal-content'>
    <div class='description'>
      <div class='description_holder'>
        <div class='description_holder_icon'>
          <tui-icon icon='@tui.file-text'></tui-icon>
        </div>
      </div>
      <h3 class='tui-text_h3 tui-space_bottom-4'>Хто такі партнери івенту?</h3>
      <p>
        Партнери івенту - це ваші спонсори. Ви можете додати лінки до їх веб-сайтів на вашу сторінку івенту.
      </p>
    </div>
    @if (form) {
      <div class='form-holder' [formGroup]='form'>
        <h2 class='tui-text_h2 tui-space_bottom-8'>Додати партнера</h2>
        <app-image-picker
          [existImageUrl]="data?.partner?.img_url"
          (selectImage)="pickImage($event)"
        ></app-image-picker>
        <tui-input
          class='tui-space_bottom-4'
          formControlName='title'>
          Название парнера
        </tui-input>
        <tui-input
          class='tui-space_bottom-4'
          formControlName='url'>
          Посилання
        </tui-input>
        <div class='modal-footer'>
          <button
            tuiButton
            type='button'
            appearance='outline'
            (click)='onCancel()'
          >
            Cancel
          </button>
          <button
            tuiButton
            type='button'
            (click)='onSubmit()'
            [disabled]='form.invalid'
          >
            Save
          </button>
        </div>
      </div>
    }
  </div>
</tui-loader>
