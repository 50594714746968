import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { EventRoutes } from './features/event/event.route';
import { MemberRoute } from './features/member/member.route';
import { PaymentRoute } from './features/payment/payment.route';
import { CompanyRoute } from './features/companies/company.route';
import { AuthRoute } from './features/auth/auth.route';

const routes: Routes = [
  {
    path: 'auth',
    children: AuthRoute
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/home/home.page.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'companies',
    children: CompanyRoute,
    canActivate: [AuthGuard],
  },
  {
    path: 'events',
    children: EventRoutes,
    canActivate: [AuthGuard],
    data: { breadcrumb: 'Events' }
  },
  {
    path: 'members',
    children: MemberRoute,
    canActivate: [AuthGuard]
  },
  {
    path: 'payments',
    children: PaymentRoute,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
