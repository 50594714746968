import { Routes } from '@angular/router';
import {
  ConfirmedEmailPage,
  ConfirmedResetPasswordPage, ConfirmRedirectPage,
  InviteMemberPage,
  NeedToConfirmEmailPage,
  NeedToResetPasswordPage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage
} from './pages';

export const AuthRoute: Routes = [
  {
    path: 'sign-in',
    component: SignInPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'sign-up',
    component: SignUpPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'need-to-confirmation',
    component: NeedToConfirmEmailPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'need-to-reset-password',
    component: NeedToResetPasswordPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'reset-password',
    component: ResetPasswordPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'confirmed-reset-password',
    component: ConfirmedResetPasswordPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'confirmed-email',
    component: ConfirmedEmailPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'callback/google',
    component: ConfirmRedirectPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'callback/apple',
    component: ConfirmRedirectPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: 'invite-member',
    component: InviteMemberPage,
    data: { title: 'man.menu.settings' }
  },
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  }
];
