import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  OnInit
} from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { TuiButton, TuiDialogContext, TuiIcon, TuiLabel, TuiLoader } from '@taiga-ui/core';
import { PaymentSettingsEntity } from '@core/api/models/payment-settings-entity';
import { AddPaymentSettingsForm } from '../../forms/add-payment-settings.form';
import { PaymentsService } from '@core/api/services/payments.service';
import { EditPaymentSettingsForm } from '../../forms/edit-payment-settings.form';
import { takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseAbstractModal } from '../../../base.abstract.modal';
import { ReactiveFormsModule } from '@angular/forms';

import { TuiCheckbox } from '@taiga-ui/kit';
import { TuiInputModule, TuiSelectModule } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.modal.html',
  styleUrl: './payment-settings.modal.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiIcon,
    TuiLoader,
    ReactiveFormsModule,
    TuiLabel,
    TuiCheckbox,
    TuiInputModule,
    TuiSelectModule,
    TranslateModule,
    TuiButton
  ],
  standalone: true
})
export class PaymentSettingsModal extends BaseAbstractModal implements OnInit {
  public form: AddPaymentSettingsForm | EditPaymentSettingsForm;
  private paymentsService = inject(PaymentsService);
  public platforms = ['liqpay'];

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        payment: PaymentSettingsEntity | null;
      }
    >
  ) {
    super();
  }

  get data(): {
    payment: PaymentSettingsEntity | null;
  } {
    return this.context.data;
  }

  ngOnInit() {
    this.showLoader.set(true);
    const { payment } = this.data;

    if (!payment) {
      this.form = new AddPaymentSettingsForm();
      this.showLoader.set(false);
    }
    if (payment) {
      this.paymentsService
        .paymentControllerFindOne({ id: payment.id })
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false))
        )
        .subscribe((res) => {
          if (res.state && res.data) {
            this.form = new EditPaymentSettingsForm(res.data);
            this.cdr.detectChanges();
          }
        });
    }
  }

  public onSubmit() {
    this.showLoader.set(true);
    const { payment } = this.data;
    if (!this.form.value) {
      this.showLoader.set(false);
      return;
    }

    if (!payment) {
      this.paymentsService
        .paymentControllerCreate({
          body: {
            active: this.form.value.active!,
            platform: this.form.value.platform!,
            name: this.form.value.name!,
            liqpay_settings: this.form.value.liqpay_settings || undefined
          }
        })
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false)))
        .subscribe((res) => this.context.completeWith(true));
      return;
    }

    this.paymentsService
      .paymentControllerUpdate({
        id: payment.id,
        body: {
          active: this.form.value.active!,
          platform: this.form.value.platform!,
          name: this.form.value.name!,
          liqpay_settings: this.form.value.liqpay_settings || undefined
        }
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false)))
      .subscribe((res) => this.context.completeWith(true));
  }

  public onCancel() {
    this.context.completeWith(false);
  }
}
