import { Component, inject, OnInit } from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { TuiButton, TuiLink, TuiLoader } from '@taiga-ui/core';
import {
  TuiInputModule,
  TuiInputPasswordModule,
  TuiIslandDirective
} from '@taiga-ui/legacy';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { signIn } from 'supertokens-web-js/lib/build/recipe/emailpassword';

@Component({
  selector: 'sign-in-page',
  templateUrl: './sign-in.page.html',
  styleUrls: ['./sign-in.page.scss'],
  imports: [
    TuiLoader,
    ReactiveFormsModule,
    TuiIslandDirective,
    TuiInputModule,
    TuiInputPasswordModule,
    TuiButton,
    TuiLink,
    RouterLink,
    TranslateModule
  ],
  standalone: true
})
export class SignInPage extends BaseAbstractPage implements OnInit {
  public loginForm: FormGroup;
  private formBuilder = inject(FormBuilder);
  private authService = inject(AuthService);

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: [
        'viacheslav.klavdiiev@gmail.com\n',
        [Validators.required, Validators.email]
      ],
      password: ['ASD965412lkj', [Validators.required]]
    });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get emailFormControl() {
    return this.loginForm.get('email') as FormControl;
  }

  get passwordFormControl() {
    return this.loginForm.get('password') as FormControl;
  }

  public singInThirdParty(type: 'google' | 'apple') {
    this.showLoader.set(true);
    this.authService.signInThirdParty(type)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false))
      ).subscribe(
      {
        next: async (res: any) => {
          window.open(res, "_self");
        },
        error: async (error) => {
          const err = error.error ? JSON.parse(error.error) : error;
          this.notificationService
            .open(err.message, {
              label: 'With a heading!',
              appearance: 'warning'
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        }
      }
    );
  }

  public async singIn() {
    this.showLoader.set(true);
    this.authService.signIn(
      this.emailFormControl.value,
      this.passwordFormControl.value
    )
      .pipe(
        finalize(() => this.showLoader.set(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        {
          next: async () => {
            await this.router.navigateByUrl('/companies');
          },
          error: err => {
            this.notificationService
              .open(err.message, { label: err.message || 'Error', appearance: 'error' })
              .subscribe();
          }
        }
      );
  }
}
