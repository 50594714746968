import {
  ChangeDetectorRef,
  DestroyRef,
  inject,
  signal,
  WritableSignal
} from '@angular/core';
import { Subject } from 'rxjs';
import { TuiAlertService } from '@taiga-ui/core';

export abstract class BaseAbstractModal {
  protected readonly destroy$ = new Subject<void>();
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly notificationService = inject(TuiAlertService);
  protected readonly cdr = inject(ChangeDetectorRef);
  public showLoader: WritableSignal<boolean> = signal(false);
}
