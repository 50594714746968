import { FormControl, FormGroup } from '@angular/forms';
import { constructFormGroupByData, FormGroupDef } from '@core/utils';
import { UpdateEventProgramDto } from '@core/api/models/update-event-program-dto';

export class EditEventProgramForm extends FormGroup<
  FormGroupDef<Partial<UpdateEventProgramDto>>
> {
  constructor(data: Partial<UpdateEventProgramDto>) {
    super(constructFormGroupByData(data));
  }
}
