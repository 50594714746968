import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiIcon, TuiButton, TuiAppearance } from '@taiga-ui/core';


@Component({
  selector: 'app-empty-section-with-action',
  templateUrl: './empty-section-with-action.component.html',
  styleUrls: ['./empty-section-with-action.component.scss'],
  imports: [TuiIcon, TuiButton, TuiAppearance],
  standalone: true
})
export class EmptySectionWithActionComponent {
  @Input() iconName: string;
  @Input() title: string;
  @Input() description: string;

  @Input() actionTitle?: string;
  @Output() action?: EventEmitter<any> = new EventEmitter();
}
