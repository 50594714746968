import { Component, OnInit } from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { TuiButton, TuiLoader } from '@taiga-ui/core';
import { TuiIslandDirective } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'need-to-reset-password-page',
  templateUrl: './need-to-reset-password.page.html',
  styleUrls: ['./need-to-reset-password.page.scss'],
  imports: [TuiLoader, TuiIslandDirective, TranslateModule, TuiButton],
  standalone: true
})
export class NeedToResetPasswordPage
  extends BaseAbstractPage
  implements OnInit
{
  ngOnInit(): void {
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  public async resendResetPassword() {}
}
