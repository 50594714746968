/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CompanyInvitationEntity } from '../models/company-invitation-entity';
import { companyInvitationsControllerCreate } from '../fn/company-invitations/company-invitations-controller-create';
import { CompanyInvitationsControllerCreate$Params } from '../fn/company-invitations/company-invitations-controller-create';
import { companyInvitationsControllerFindAll } from '../fn/company-invitations/company-invitations-controller-find-all';
import { CompanyInvitationsControllerFindAll$Params } from '../fn/company-invitations/company-invitations-controller-find-all';
import { companyInvitationsControllerFindById } from '../fn/company-invitations/company-invitations-controller-find-by-id';
import { CompanyInvitationsControllerFindById$Params } from '../fn/company-invitations/company-invitations-controller-find-by-id';
import { companyInvitationsControllerRemove } from '../fn/company-invitations/company-invitations-controller-remove';
import { CompanyInvitationsControllerRemove$Params } from '../fn/company-invitations/company-invitations-controller-remove';

@Injectable({ providedIn: 'root' })
export class CompanyInvitationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `companyInvitationsControllerFindAll()` */
  static readonly CompanyInvitationsControllerFindAllPath =
    '/api/v1/company/invitations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyInvitationsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerFindAll$Response(
    params: CompanyInvitationsControllerFindAll$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: {
        total?: number;
        limit?: number;
        page?: number;
        items?: Array<CompanyInvitationEntity>;
      };
      message?: string;
    }>
  > {
    return companyInvitationsControllerFindAll(
      this.http,
      this.rootUrl,
      params,
      context
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyInvitationsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerFindAll(
    params: CompanyInvitationsControllerFindAll$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<CompanyInvitationEntity>;
    };
    message?: string;
  }> {
    return this.companyInvitationsControllerFindAll$Response(
      params,
      context
    ).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: {
              total?: number;
              limit?: number;
              page?: number;
              items?: Array<CompanyInvitationEntity>;
            };
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: {
            total?: number;
            limit?: number;
            page?: number;
            items?: Array<CompanyInvitationEntity>;
          };
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `companyInvitationsControllerCreate()` */
  static readonly CompanyInvitationsControllerCreatePath =
    '/api/v1/company/invitations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyInvitationsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companyInvitationsControllerCreate$Response(
    params: CompanyInvitationsControllerCreate$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: CompanyInvitationEntity;
      message?: string;
    }>
  > {
    return companyInvitationsControllerCreate(
      this.http,
      this.rootUrl,
      params,
      context
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyInvitationsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companyInvitationsControllerCreate(
    params: CompanyInvitationsControllerCreate$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: CompanyInvitationEntity;
    message?: string;
  }> {
    return this.companyInvitationsControllerCreate$Response(
      params,
      context
    ).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: CompanyInvitationEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: CompanyInvitationEntity;
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `companyInvitationsControllerFindById()` */
  static readonly CompanyInvitationsControllerFindByIdPath =
    '/api/v1/company/invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyInvitationsControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerFindById$Response(
    params: CompanyInvitationsControllerFindById$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: CompanyInvitationEntity;
      message?: string;
    }>
  > {
    return companyInvitationsControllerFindById(
      this.http,
      this.rootUrl,
      params,
      context
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyInvitationsControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerFindById(
    params: CompanyInvitationsControllerFindById$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: CompanyInvitationEntity;
    message?: string;
  }> {
    return this.companyInvitationsControllerFindById$Response(
      params,
      context
    ).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: CompanyInvitationEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: CompanyInvitationEntity;
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `companyInvitationsControllerRemove()` */
  static readonly CompanyInvitationsControllerRemovePath =
    '/api/v1/company/invitations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyInvitationsControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerRemove$Response(
    params: CompanyInvitationsControllerRemove$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: CompanyInvitationEntity;
      message?: string;
    }>
  > {
    return companyInvitationsControllerRemove(
      this.http,
      this.rootUrl,
      params,
      context
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `companyInvitationsControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyInvitationsControllerRemove(
    params: CompanyInvitationsControllerRemove$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: CompanyInvitationEntity;
    message?: string;
  }> {
    return this.companyInvitationsControllerRemove$Response(
      params,
      context
    ).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: CompanyInvitationEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: CompanyInvitationEntity;
          message?: string;
        } => r.body
      )
    );
  }
}
