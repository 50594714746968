import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(event => {
      const routerState = activatedRoute.snapshot;

      let route = routerState.root;

      while (route.firstChild) {
        route = route.firstChild;
      }

      const breadcrumbs = this.createBreadcrumbs(route);
      this._breadcrumbs$.next(breadcrumbs);
    });
  }

  private createBreadcrumbs(
    route: ActivatedRouteSnapshot,
    breadcrumbs: Array<{ label: string; url: string }> = []
  ): Array<{ label: string; url: string }> {
    if (route.routeConfig?.path && route.data['breadcrumb']) {
      breadcrumbs.unshift({
        label: this.getLabel(route.data),
        url: route.routeConfig?.path || ''
      });
    }
    const parent: ActivatedRouteSnapshot | null = route.parent;
    if (!parent) {
      return breadcrumbs;
    }

    return this.createBreadcrumbs(parent, breadcrumbs);
  }

  private getLabel(data: Data) {
    return typeof data.breadcrumb === 'function' ? data.breadcrumb(data) : data.breadcrumb;
  }

}
