<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div class="container" [formGroup]="signUpForm">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <tui-island class="with-bg" style="background-image: url('assets/auth-bg-2.jpg')">
          <div class="logo-holder">
            <img src="assets/single-logo-white.svg">
          </div>
        </tui-island>
      </div>
      <div class="col-xs-12 col-md-6">
        <tui-island>
          <div class="form-holder">
            <h1 class="tui-space_bottom-2 center-xs">{{ 'anms.settings.title' | translate }}</h1>
            <p class="tui-space_bottom-8 center-xs">You invited to {{params()?.companyName}}</p>
            <tui-input class="tui-space_bottom-4" formControlName="email">
              E-mail
            </tui-input>
            <tui-input-password class="tui-space_bottom-4" formControlName="password">
              Password
            </tui-input-password>
            <tui-input-password class="tui-space_bottom-4" formControlName="confirmPassword">
              Confirm password
            </tui-input-password>

            <footer>
              <button
                tuiButton
                type="button"
                appearance="primary"
                [disabled]="signUpForm.invalid"
                (click)="authorize()"
                class="sign-in-btn tui-space_bottom-7"
              >
                Register
              </button>
              <button
                tuiLink
                type="button"
                routerLink="/auth/sign-in"
                class="styled-link"
              >
                Sign in
              </button>
            </footer>
          </div>
        </tui-island>
      </div>
    </div>
  </div>
</tui-loader>
