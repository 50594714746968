import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthCompanyService } from '@core/services/auth-company.service';

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {
  private authCompanyService = inject(AuthCompanyService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const company = this.authCompanyService.current();
    if (company) {
      const cloned = req.clone({
        headers: req.headers.set('X-Company-Id', company.id)
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
