import {
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { TuiButton, TuiDialogService, TuiIcon, TuiLoader, TuiSurface, TuiTitle } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { finalize } from 'rxjs/operators';
import { EventArtistEntity } from '@core/api/models/event-artist-entity';
import { EventArtistModal } from '../../modals/event-artist/event-artist.modal';
import { EventService } from '@core/api/services';
import { AsyncPipe } from '@angular/common';
import {
  TuiTableCell,
  TuiTableDirective,
  TuiTableTbody,
  TuiTableTd,
  TuiTableTh,
  TuiTableThGroup,
  TuiTableTr
} from '@taiga-ui/addon-table';
import { ImageUrlPipe } from '@core/pipes/image-url.pipe';
import { EmptySectionWithActionComponent } from '@shared/empty-section-with-action/empty-section-with-action.component';
import { TuiAvatar } from '@taiga-ui/kit';
import { TuiCardLarge, TuiHeader } from '@taiga-ui/layout';
import { BaseAbstractComponent } from '../../../base.abstract.component';

@Component({
  selector: 'event-artists-list-component',
  templateUrl: './event-artists-list.component.html',
  styleUrls: ['./event-artists-list.component.scss'],
  standalone: true,
  imports: [
    TuiLoader,
    AsyncPipe,
    TuiButton,
    TuiTableDirective,
    TuiTableThGroup,
    TuiTableTh,
    TuiTableTbody,
    TuiTableTr,
    TuiTableTd,
    TuiTableCell,
    ImageUrlPipe,
    EmptySectionWithActionComponent,
    TuiIcon,
    TuiAvatar,
    TuiCardLarge,
    TuiSurface,
    TuiHeader,
    TuiTitle
  ]
})
export class EventArtistsListComponent extends BaseAbstractComponent implements OnInit {
  @Input() eventId!: string;
  private eventsService = inject(EventService);
  private dialogs = inject(TuiDialogService);
  public eventArtists$: Observable<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<EventArtistEntity>;
    };
    message?: string;
  }>;
  public readonly displayedColumns: string[] = [
    'img_url',
    'description',
    'type',
    'links',
    'actions'
  ];

  ngOnInit(): void {
    this.loadData();

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  loadData() {
    this.showLoader.set(true);
    this.eventArtists$ = this.eventsService
      .eventControllerFindAllArtistsByEventId({
        event_id: this.eventId,
        limit: 50,
        page: 1
      })
      .pipe(
        finalize(() => this.showLoader.set(false))
      );
  }

  add(artist?: EventArtistEntity) {
    this.dialogs
      .open<boolean>(new PolymorpheusComponent(EventArtistModal), {
        data: {
          event_id: this.eventId,
          artist: artist || null
        },
        dismissible: true,
        appearance: 'custom-modal'
      })
      .pipe(
        finalize(() => takeUntil(this.destroy$))
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.loadData();
          }
          console.info(`Dialog emitted data = ${data}`);
        }
      });
  }

}
