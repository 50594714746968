/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateEventLinkDto } from '../../models/create-event-link-dto';
import { EventLinkEntity } from '../../models/event-link-entity';

export interface EventControllerCreateLink$Params {
  event_id: string;
  body: CreateEventLinkDto;
}

export function eventControllerCreateLink(
  http: HttpClient,
  rootUrl: string,
  params: EventControllerCreateLink$Params,
  context?: HttpContext
): Observable<
  StrictHttpResponse<{
    state?: boolean;
    data?: EventLinkEntity;
    message?: string;
  }>
> {
  const rb = new RequestBuilder(
    rootUrl,
    eventControllerCreateLink.PATH,
    'post'
  );
  if (params) {
    rb.path('event_id', params.event_id, {});
    rb.body(params.body, 'application/json');
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
          state?: boolean;
          data?: EventLinkEntity;
          message?: string;
        }>;
      })
    );
}

eventControllerCreateLink.PATH = '/api/v1/events/{event_id}/links';
