@if (theme$ | async; as theme) {
  <tui-root [attr.tuitheme]="theme">
    @if (user$ | async; as user) {
      <header tuiNavigationHeader [attr.tuitheme]="theme">
        <span tuiNavigationLogo>
          <tui-icon icon="@tui.home" />
          <span tuiFade>TktSpace</span>
          @if (!environment.production) {
            <tui-badge>Test</tui-badge>
          }
        </span>
        <hr />
        <app-theme-switcher></app-theme-switcher>
        <button
          iconStart="@tui.bell"
          tuiIconButton
        >
          Notifications
          <tui-badge-notification />
        </button>
        <button
          tuiButton
          tuiDropdownOpen
          [tuiDropdown]="languageMenu"
          [tuiAppearanceState]="openLanguage ? 'hover' : null"
          [(tuiDropdownOpen)]="openLanguage"
        >
          {{ language | translate }}
        </button>
        <ng-template #languageMenu>
          <tui-data-list size="m">
            @for (ln of languages; track ln) {
              <button [attr.tuitheme]="theme" tuiOption [class.active]="ln === language"
                      (click)="selectLanguage(ln)">{{ ln | translate }}
              </button>
            }
          </tui-data-list>
        </ng-template>
        <button
          iconStart="@tui.ellipsis"
          tuiIconButton
          tuiDropdownOpen
          [tuiDropdown]="headerMenu"
          [tuiAppearanceState]="openUser ? 'hover' : null"
          [(tuiDropdownOpen)]="openUser"
        >
          More
        </button>
        <ng-template #headerMenu>
          <tui-data-list size="m">
            <button tuiOption [attr.tuitheme]="theme">{{ 'profile#edit_profile' | translate }}</button>
            <button tuiOption [attr.tuitheme]="theme" (click)="onLogoutClick()">{{ 'auth#logout' | translate }}</button>
          </tui-data-list>
        </ng-template>
        <tui-avatar src="@tui.user" />
      </header>
      @if (activeCompany$ | async; as company) {
        <div [style.display]="'flex'" [style.min-height.%]="100">
          <aside
            [tuiNavigationAside]="expanded"
            [attr.tuitheme]="theme"
          >
            <button
              iconEnd="@tui.chevron-right"
              tuiAsideItem
              tuiDropdownOpen
              [tuiDropdown]="more"
              [tuiAppearanceState]="openCompany ? 'hover' : null"
              [(tuiDropdownOpen)]="openCompany"
            >
              <tui-avatar
                size="xs"
                src='{{company?.logo_url | imageUrl}}'
              />
              {{ company?.name }}
              <ng-template
                #more
                let-close
              >
                <tui-data-list size="m" tuiDataListDropdownManager>
                  <button
                    [attr.tuitheme]="theme"
                    tuiAsideItem
                    tuiOption
                    (click)="onEditCompany(company.id)"
                  >
                    {{ 'company#edit_company' | translate }}
                  </button>
                  <button
                    [attr.tuitheme]="theme"
                    tuiAsideItem
                    tuiOption
                    (click)="onResetCompany()"
                  >
                    {{ 'company#logout_company' | translate }}
                  </button>
                </tui-data-list>
              </ng-template>
            </button>
            <hr />
            @for (item of menu; track item.link) {
              <a
                [iconStart]="item.icon"
                tuiAsideItem
                [routerLink]="item.link"
              >
                {{ item.title }}
              </a>
            }
            <hr />
            <button
              iconStart="@tui.plus"
              tuiAsideItem
            >
              Add
            </button>
            <footer>
              <button
                tuiAsideItem
                [iconStart]="expanded ? '@tui.chevron-left' : '@tui.chevron-right'"
                (click)="expanded = !expanded"
              >
                {{ expanded ? 'Collapse' : 'Expand' }}
              </button>
            </footer>
          </aside>
          <main tuiNavigationMain [attr.tuitheme]="theme">
            <div class="page-wrapper">
              @if (breadcrumbs$ | async; as breadcrumbs; ) {
                @if (breadcrumbs && breadcrumbs.length > 1) {
                  <nav tuiNavigationNav [style.padding-bottom.rem]=".25">
                    <a
                      tuiButton
                      appearance="secondary"
                      [routerLink]="breadcrumbs[breadcrumbs.length - 2].url"
                      [style.margin-right.px]="15"
                    >
                      <tui-icon icon="@tui.chevron-left" />
                      Back
                    </a>
                    <tui-breadcrumbs>
                      @for (item of breadcrumbs; track item.url) {
                        <a
                          *tuiItem
                          tuiLink
                          [routerLink]="item.url"
                        >
                          {{ item.label }}
                        </a>
                      }
                    </tui-breadcrumbs>
                  </nav>
                }
              }
              <div class="tui-space_bottom-8"></div>
              <router-outlet></router-outlet>
            </div>
          </main>
        </div>
      } @else {
        <div class="page-wrapper container">
          <router-outlet style="display: none;"></router-outlet>
        </div>
      }
    } @else {
      <header tuiNavigationHeader [attr.tuitheme]="theme">
        <span tuiNavigationLogo>
          <tui-icon icon="@tui.home" />
          <span tuiFade>TktSpace</span>
          @if (!environment.production) {
            <tui-badge>Test</tui-badge>
          }
        </span>
        <hr />
        <app-theme-switcher></app-theme-switcher>
      </header>
      <div class="page-wrapper">
        <router-outlet style="display: none;"></router-outlet>
      </div>
    }

    <ng-container ngProjectAs="tuiOverContent">
    </ng-container>
    <ng-container ngProjectAs="tuiOverDialogs">
    </ng-container>
    <ng-container ngProjectAs="tuiOverAlerts">
    </ng-container>
    <ng-container ngProjectAs="tuiOverDropdowns">
    </ng-container>
    <ng-container ngProjectAs="tuiOverHints">
    </ng-container>
  </tui-root>
}

