import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private localStorageService = inject(LocalStorageService)

  private themeSubject: BehaviorSubject<'dark' | 'light'> = new BehaviorSubject<any>(this.localStorageService.getItem('theme') || 'light');
  public theme$: Observable<any> = this.themeSubject.asObservable();


  public current() {
    return this.themeSubject.value;
  }

  public set(theme: 'dark' | 'light') {
    this.localStorageService.setItem('theme', theme);
    this.themeSubject.next(theme);
  }
}
