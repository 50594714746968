<tui-loader [overlay]='true' [showLoader]='this.showLoader()'>
  @if (eventPartner$ | async; as partner) {
    <div class="tui-space_bottom-8">
      <div tuiHeader="xl">
        <h2 tuiTitle>
          <span>Блок програма</span>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
        </h2>

        <div tuiAccessories>
          <button
            tuiButton
            type='button'
            size='m'
            (click)='add()'
          >
            <tui-icon icon='@tui.plus'></tui-icon>
            Add new
          </button>
        </div>
      </div>
    </div>
    @if (partner?.data.items.length !== 0) {
      <div tuiCardLarge tuiSurface="elevated">
        <table
          tuiTable
          class='table'
          [columns]='displayedColumns'
        >
          <thead>
          <tr tuiThGroup>
            <th
              tuiTh
              [resizable]='true'
            >
              Картинка та назва пункту
            </th>
            <th tuiTh>Назва</th>
            <th tuiTh>Опис</th>
            <th tuiTh></th>
          </tr>
          </thead>
          <tbody
            tuiTbody
            [data]='partner.data.items'
          >
            @for (item of partner.data.items; track item) {
              <tr
                tuiTr
              >
                <td
                  *tuiCell="'img_url'"
                  tuiTd
                >
                  <tui-avatar size="s" src="{{item.img_url | imageUrl}}"></tui-avatar>
                </td>
                <td
                  *tuiCell="'title'"
                  tuiTd
                >
                  {{ item.title || '-' }}
                </td>
                <td
                  *tuiCell="'time'"
                  tuiTd
                >
                  {{ item.url || '-' }}
                </td>
                <td
                  class='text-right'
                  *tuiCell="'actions'"
                  tuiTd
                >
                  <tui-icon class='icon-button' (click)='add(item)' icon="@tui.pencil"></tui-icon>
                </td>
              </tr>
            }
          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>
    }
    @if (partner?.data.items.length === 0) {
      <app-empty-section-with-action
        [title]="'Ви ще не додавали учасників'"
        [description]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.'"
        [iconName]="'@tui.users'"
        [actionTitle]="'Додати учасника івенту'"
        (action)='add()'
      ></app-empty-section-with-action>
    }
  }
</tui-loader>
