import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject, OnInit, signal, WritableSignal
} from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import { TuiButton, TuiDialogContext, TuiIcon, TuiLoader } from '@taiga-ui/core';
import { AddEventPartnerForm, EditEventPartnerForm } from '../../forms';
import { EventPartnerEntity } from '@core/api/models/event-partner-entity';
import { EventService } from '@core/api/services';
import { ReactiveFormsModule } from '@angular/forms';
import { of, switchMap, takeUntil } from 'rxjs';
import { TuiInputModule } from '@taiga-ui/legacy';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';
import { BaseAbstractModal } from '../../../base.abstract.modal';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'event-partner-component',
  templateUrl: './event-partner.modal.html',
  styleUrls: ['./event-partner.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TuiButton,
    TuiInputModule,
    TuiIcon,
    ImagePickerComponent,
    TuiLoader
  ],
  standalone: true
})
export class EventPartnerModal extends BaseAbstractModal implements OnInit {
  public form: AddEventPartnerForm | EditEventPartnerForm;
  private eventService = inject(EventService);
  public image: WritableSignal<any> = signal(null);

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        event_id: string;
        partner: EventPartnerEntity | null;
      }
    >
  ) {
    super();

  }

  ngOnInit() {
    this.showLoader.set(true);
    const { partner } = this.data;

    if (!partner) {
      this.form = new AddEventPartnerForm();
      this.showLoader.set(false);
    }

    if (partner) {
      this.form = new EditEventPartnerForm({
        title: partner.title || '',
        order: partner.order || 0,
        url: partner.url || ''
      });
      this.showLoader.set(false);
    }

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get data(): {
    event_id: string;
    partner: EventPartnerEntity | null;
  } {
    return this.context.data;
  }

  pickImage(image: any) {
    this.image.set(image);
  }

  public onSubmit() {
    const { partner } = this.data;
    if (!this.form.value) {
      return;
    }
    this.showLoader.set(true);

    if (!partner) {
      this.eventService
        .eventControllerCreatePartner({
          event_id: this.data.event_id,
          body: {
            order: this.form.value.order!,
            title: this.form.value.title!,
            url: this.form.value.url!
          }
        })
        .pipe(
          switchMap((res) =>
            this.image() && res.data?.id
              ? this.eventService.eventControllerUpdatePartnerImage({
                id: res.data.id,
                body: { image: this.image() }
              })
              : of(res)
          ),
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false))
        )
        .subscribe((res) => this.context.completeWith(true));
    }

    if (partner) {
      this.eventService
        .eventControllerUpdatePartner({
          id: this.data.partner?.id!,
          body: { ...this.form.value }
        })
        .pipe(
          switchMap((res) =>
            this.image() && res.data?.id
              ? this.eventService.eventControllerUpdatePartnerImage({
                id: res.data.id,
                body: { image: this.image() }
              })
              : of(res)
          ),
          takeUntil(this.destroy$),
          finalize(() => this.showLoader.set(false))
        )
        .subscribe((res) => this.context.completeWith(true));
    }
  }

  public onCancel() {
    this.context.completeWith(false);
  }
}
