import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { TuiButton, TuiLoader } from '@taiga-ui/core';
import { TuiIslandDirective } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'confirmed-email-page',
  templateUrl: './confirmed-email.page.html',
  styleUrls: ['./confirmed-email.page.scss'],
  imports: [TuiLoader, TuiIslandDirective, TranslateModule, TuiButton],
  standalone: true
})
export class ConfirmedEmailPage extends BaseAbstractPage implements OnInit {
  public token: WritableSignal<string | null> = signal(null);

  ngOnInit(): void {
    if (this.route.snapshot.queryParams?.token) {
      this.token.set(this.route.snapshot.queryParams.token);
    }

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  public async authorize() {
    this.showLoader.set(true);
    const token = this.token();

    if (!token) {
      this.showLoader.set(false);
      return;
    }
    // this.authService
    //   .authControllerConfirmEmail({ token })
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     finalize(() => this.showLoader.set(false))
    //   )
    //   .subscribe({
    //     next: async (res: any) => {
    //       const result = JSON.parse(res);
    //       await this.router.navigateByUrl('/companies');
    //     },
    //     error: (error) =>
    //       this.notificationService
    //         .open(error.message, {
    //           label: 'With a heading!',
    //           status: 'warning'
    //         })
    //         .pipe(takeUntil(this.destroy$))
    //         .subscribe()
    //   });
  }
}
