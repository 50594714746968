<header class="row middle-xs p-header" [ngClass]="{'underline': underline}">
  <div class="col-xs-8">
    @if (title) {
      <h1 class="header_title tui-text_h1">{{ title }}</h1>
    }
    @if (description) {
      <p>{{description}}</p>
    }
  </div>
  @if (actionTitle && action) {
    <div class="col-xs-4 end-xs ">
      <button
        tuiButton
        type="button"
        (click)="action.emit()"
        >
        {{actionTitle}}
      </button>
    </div>
  }
</header>
