import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupDef } from '@core/utils';
import { CreateEventArtistDto } from '@core/api/models/create-event-artist-dto';
import { CreatePaymentDto } from '@core/api/models/create-payment-dto';

export class AddPaymentSettingsForm extends FormGroup<
  FormGroupDef<Partial<CreatePaymentDto>>
> {
  constructor() {
    super({
      name: new FormControl('', { nonNullable: true }),
      platform: new FormControl('liqpay', { nonNullable: true }),
      active: new FormControl(true, { nonNullable: true })
    });
    this.controls.name?.addValidators([Validators.required]);
    this.addControl(
      'liqpay_settings',
      new FormGroup({
        private_key: new FormControl('', { nonNullable: true }),
        public_key: new FormControl('', { nonNullable: true })
      })
    );
  }
}
