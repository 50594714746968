import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupDef } from '@core/utils';
import { CreateEventArtistDto } from '@core/api/models/create-event-artist-dto';

export class AddEventArtistForm extends FormGroup<
  FormGroupDef<Partial<CreateEventArtistDto>>
> {
  constructor() {
    super({
      name: new FormControl('', { nonNullable: true }),
      description: new FormControl('', { nonNullable: true }),
      type: new FormControl('', { nonNullable: true }),
      order: new FormControl(0, { nonNullable: true }),
      // @ts-ignore
      links: new FormArray([new FormControl('', { nonNullable: true })]),
      img: new FormControl()
    });

    this.controls.name?.addValidators([Validators.required]);
    this.controls.description?.addValidators([Validators.required]);
    this.controls.type?.addValidators([Validators.required]);
    this.controls.links?.addValidators([Validators.required]);
  }

  addImageControl() {
    const imageControl = new FormControl(null);
    this.addControl('img', imageControl);
  }

  get linksArray() {
    // @ts-ignore
    return this.controls['links'] as FormArray;
  }

  addLink() {
    this.linksArray.push(new FormControl(''));
  }

  removeLink(index: number) {
    this.linksArray &&
      this.linksArray.length > 0 &&
      this.linksArray.controls.splice(index, 1);
  }
}
