import { FormGroup } from '@angular/forms';
import { constructFormGroupByData, FormGroupDef } from '@core/utils';
import { UpdatePaymentDto } from '@core/api/models/update-payment-dto';
import { PaymentSettingsEntity } from '@core/api/models/payment-settings-entity';

export class EditPaymentSettingsForm extends FormGroup<
  FormGroupDef<Partial<UpdatePaymentDto>>
> {
  constructor(data: Partial<PaymentSettingsEntity>) {
    const formData: Partial<UpdatePaymentDto> = {
      name: data.name,
      platform: data.platform,
      active: data.active
    };

    if (data.platform === 'liqpay' && data.liqpay_settings) {
      formData.liqpay_settings = {
        private_key: data.liqpay_settings.private_key,
        public_key: data.liqpay_settings.public_key
      };
    }
    super(constructFormGroupByData(data));
  }
}
