/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CompanyMemberEntity } from '../../models/company-member-entity';

export interface CompanyMembersControllerGetByUser$Params {
  id: string;
}

export function companyMembersControllerGetByUser(
  http: HttpClient,
  rootUrl: string,
  params: CompanyMembersControllerGetByUser$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<CompanyMemberEntity>> {
  const rb = new RequestBuilder(
    rootUrl,
    companyMembersControllerGetByUser.PATH,
    'get'
  );
  if (params) {
    rb.path('id', params.id, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyMemberEntity>;
      })
    );
}

companyMembersControllerGetByUser.PATH = '/api/v1/company-members/user/{id}';
