import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private localStorageService = inject(LocalStorageService);
  private translateService = inject(TranslateService);


  private languageSubject: BehaviorSubject<'en' | 'uk' | 'de' | 'fr'> = new BehaviorSubject<any>(this.localStorageService.getItem('lang') || 'en');
  public language$: Observable<any> = this.languageSubject.asObservable();

  constructor() {
    this.translateService.use(this.current());
  }


  public current() {
    return this.languageSubject.value;
  }

  public set(lang: 'en' | 'uk' | 'de' | 'fr') {
    this.localStorageService.setItem('lang', lang);
    this.languageSubject.next(lang);
    this.translateService.use(lang);
  }
}
