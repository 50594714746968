import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { Subscription, timer } from 'rxjs';
import { TuiButton, TuiLoader } from '@taiga-ui/core';
import { TuiIslandDirective } from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'need-to-confirm-email-page',
  templateUrl: './need-to-confirm-email.page.html',
  styleUrls: ['./need-to-confirm-email.page.scss'],
  imports: [
    TuiLoader,
    TuiIslandDirective,
    TranslateModule,
    TuiButton,
    DatePipe
  ],
  standalone: true
})
export class NeedToConfirmEmailPage extends BaseAbstractPage implements OnInit {
  public resendCodeTimer: WritableSignal<number> = signal(119);
  public obsTimer: Subscription;

  ngOnInit(): void {
    this.resendCodeCountDown();
    this.destroyRef.onDestroy(() => {
      this.obsTimer && this.obsTimer.unsubscribe();
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  resendCodeCountDown() {
    if (this.obsTimer) {
      this.obsTimer.unsubscribe();
    }
    this.resendCodeTimer.set(119);
    this.obsTimer = timer(0, 1000).subscribe((val) => {
      if (this.resendCodeTimer() === 0) {
        this.obsTimer.unsubscribe();
      } else {
        this.resendCodeTimer.set(119 - val);
      }
    });
  }

  public async resendConfirmationEmail() {}
}
