import { Routes } from '@angular/router';
import { MemberCreatePage, MemberDetailPage, MemberListPage } from './pages';

export const MemberRoute: Routes = [
  {
    path: '',
    component: MemberListPage,
    data: { breadcrumb: 'Members' }
  },
  {
    path: 'event-detail/:id',
    component: MemberDetailPage,
    data: { breadcrumb: 'View' }
  },
  {
    path: 'create',
    component: MemberCreatePage,
    data: { breadcrumb: 'Create' }
  }
];
