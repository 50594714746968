<tui-loader [overlay]="true" [showLoader]="showLoader()">
  @if (editForm) {
    <div tuiCardLarge tuiSurface="elevated" [formGroup]="editForm">
      <div class="row">
        <div class="col-sm-12">
          <h1 class='tui-text_h1'>{{ 'company#edit_company' | translate }}</h1>
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h2 class='tui-text_h2 tui-space_bottom-4'>{{ 'company#create_edit#logo#titile' | translate }}</h2>
          <p> {{ 'company#create_edit#logo#description' | translate }}</p>
        </div>
        <div class="col-md-6">
          <app-image-picker
            [existImageUrl]="data.logo_url"
            (selectImage)="pickImage($event)"
          ></app-image-picker>
        </div>
      </div>
      <div class='row'>
        <div class='col-md-6'>
          <h2 class='tui-text_h2 tui-space_bottom-4'>{{ 'company#create_edit#info#titile' | translate }}</h2>
          <p>{{ 'company#create_edit#info#description' | translate }}</p>
        </div>
        <div class='col-md-6'>
          <tui-input class='tui-space_bottom-4 field-large' formControlName='name'>
            {{ 'company#form#name' | translate }}
            <input
              tuiTextfieldLegacy
              type='text'
            />
          </tui-input>
          <tui-input class='tui-space_bottom-4 field-large' formControlName='email'>
            {{ 'company#form#email' | translate }}
            <input
              tuiTextfieldLegacy
              type='email'
            />
          </tui-input>
          <tui-input
            formControlName='specialization'
            class='tui-space_bottom-4 field-large'
          >
            {{ 'company#form#specialization' | translate }}
          </tui-input>
        </div>
        <div class='col-xs-12'>
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <button
            tuiButton
            type="button"
            appearance="destructive"
            (click)="deleteOrganization()"
            class="tui-space_right-3 tui-space_bottom-3"
          >
            {{ 'common#delete' | translate }}
          </button>

        </div>
        <div class="col-md-6 end-xs">
          <button
            tuiButton
            type="button"
            appearance="outline"
            (click)="back()"
            class="tui-space_right-3 tui-space_bottom-3"
          >
            {{ 'common#cancel' | translate }}
          </button>
          <button
            tuiButton
            type="button"
            class="tui-space_right-3 tui-space_bottom-3"
            (click)="editOrganization()"
            [disabled]="editForm.invalid"
          >
            {{ 'common#save' | translate }}
          </button>
        </div>
      </div>
    </div>
  }
</tui-loader>
