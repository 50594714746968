import {
  TuiInputDateRangeModule,
  TuiInputModule,
  TuiInputNumberModule,
  tuiInputNumberOptionsProvider,
  TuiInputTimeModule,
  tuiInputTimeOptionsProvider,
  TuiSelectModule,
  TuiTextfieldControllerModule,
  TuiUnfinishedValidator
} from '@taiga-ui/legacy';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit, signal, WritableSignal
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { of, switchMap, takeUntil } from 'rxjs';
import {
  TuiCheckbox,
  tuiCreateTimePeriods,
} from '@taiga-ui/kit';
import { TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import { BaseAbstractPage } from '../../../base.abstract.page';
import { EventService } from '@core/api/services';
import { TuiButton, TuiIcon, TuiLabel, tuiNumberFormatProvider, TuiSurface } from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImagePickerComponent } from '@shared/image-picker/image-picker.component';
import { TuiCardLarge } from '@taiga-ui/layout';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'event-member-company-create-page',
  templateUrl: './event-create.page.html',
  styleUrls: ['./event-create.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiButton,
    ReactiveFormsModule,
    TranslateModule,
    AsyncPipe,
    TuiInputModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiInputDateRangeModule,
    TuiUnfinishedValidator,
    TuiInputTimeModule,
    TuiInputNumberModule,
    TuiCheckbox,
    TuiLabel,
    TuiIcon,
    ImagePickerComponent,
    TuiCardLarge,
    TuiSurface
  ],
  standalone: true
})
export class EventCreatePage extends BaseAbstractPage implements OnInit {
  public createForm: FormGroup;
  private formBuilder = inject(FormBuilder);
  private eventService = inject(EventService);

  public image: WritableSignal<any> = signal(null);

  public eventTypes = ['online', 'physical'];
  public eventStatusTypes = ['stand_by', 'public'];
  public smartTicketsTypes = ['none', 'only', 'any'];
  readonly min = new TuiDay(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );
  readonly max = new TuiDay(2040, 2, 20);
  public timePeriod = tuiCreateTimePeriods();

  async ngOnInit() {
    const date = new Date();
    this.createForm = this.formBuilder.group({
      age_rating: [''],
      draft: [true, [Validators.required]],
      title: ['', [Validators.required]],
      smart_tickets: ['any', [Validators.required]],
      status: ['stand_by', [Validators.required]],
      type: [this.eventTypes[0], [Validators.required]],
      dateRangeControl: [
        new TuiDayRange(
          new TuiDay(date.getFullYear(), date.getMonth(), date.getDate()),
          new TuiDay(date.getFullYear(), date.getMonth() + 1, date.getDate())
        ),
        [Validators.required]
      ],
      startTime: ['', [Validators.required]],
      endTime: ['', [Validators.required]]
    });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  pickImage(image: any) {
    this.image.set(image);
  }

  create() {
    this.showLoader.set(true);
    const data = this.createForm.value;
    const start_date_time = new Date(
      this.createForm.value.dateRangeControl.from.year,
      this.createForm.value.dateRangeControl.from.month,
      this.createForm.value.dateRangeControl.from.day,
      this.createForm.value.startTime.hours,
      this.createForm.value.startTime.minutes,
      this.createForm.value.startTime.seconds
    );
    const end_date_time = new Date(
      this.createForm.value.dateRangeControl.from.year,
      this.createForm.value.dateRangeControl.from.month,
      this.createForm.value.dateRangeControl.from.day,
      this.createForm.value.endTime.hours,
      this.createForm.value.endTime.minutes,
      this.createForm.value.endTime.seconds
    );

    data.start_date_time = start_date_time;
    data.end_date_time = end_date_time;

    delete data.dateRangeControl;
    delete data.startTime;
    delete data.endTime;

    return this.eventService
      .eventControllerCreate({
        body: data
      })
      .pipe(
        switchMap((res) =>
          res.state && res?.data?.id
            ? this.eventService.eventControllerUpdateGeneralInfoMainImage({
                event_id: res.data.id,
                body: { image: this.image() }
              })
            : of(res)
        )
      )
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.showLoader.set(false);
        }))
      .subscribe({
        next: async (res) => {
          this.notificationService
            .open('Event successfully created', {
              label: 'With a heading!',
              appearance: 'success'
            })
            .subscribe();
          await this.router.navigateByUrl('/events');
          console.log('success', res);
        },
        error: (error) => {
          this.notificationService
            .open('Cannot create organization', {
              label: 'With a heading!',
              appearance: 'warning'
            })
            .subscribe();
          console.log('error', error);
        }
      });
  }
}
