import { Component, inject, OnInit } from '@angular/core';
import { BaseAbstractPage } from '../../../base.abstract.page';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { TuiButton, TuiError, TuiLink, TuiLoader, TuiTextfieldOptionsDirective } from '@taiga-ui/core';
import {
  TuiInputModule,
  TuiInputPasswordModule,
  TuiIslandDirective
} from '@taiga-ui/legacy';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { signUp } from 'supertokens-web-js/recipe/emailpassword';
import { TuiFieldErrorPipe } from '@taiga-ui/kit';
import { AsyncPipe } from '@angular/common';


@Component({
  selector: 'sign-up-page',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
  imports: [
    TuiLoader,
    ReactiveFormsModule,
    TuiIslandDirective,
    TuiInputModule,
    TranslateModule,
    TuiInputPasswordModule,
    TuiButton,
    TuiLink,
    RouterLink,
    TuiError,
    TuiFieldErrorPipe,
    AsyncPipe,
    TuiTextfieldOptionsDirective
  ],
  standalone: true
})
export class SignUpPage extends BaseAbstractPage implements OnInit {
  public signUpForm: FormGroup;
  private formBuilder = inject(FormBuilder);
  private authService = inject(AuthService);

  ngOnInit(): void {
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    });

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get emailFormControl() {
    return this.signUpForm.get('email') as FormControl;
  }

  get passwordFormControl() {
    return this.signUpForm.get('password') as FormControl;
  }

  get confirmPasswordFormControl() {
    return this.signUpForm.get('confirmPassword') as FormControl;
  }

  public async singUp() {

    if (this.passwordFormControl.value !== this.confirmPasswordFormControl.value) {
      this.confirmPasswordFormControl.markAsDirty();
    }
    this.showLoader.set(true);
    this.authService.signUp(
      this.emailFormControl.value,
      this.passwordFormControl.value
    )
      .pipe(
        finalize(() => this.showLoader.set(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        {
          next: async () => {
            await this.router.navigateByUrl('/companies');
          },
          error: err => {
            this.notificationService
              .open(err.message, { label: err.message || 'Error', appearance: 'error' })
              .subscribe();
          }
        }
      );
  }
}
