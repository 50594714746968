/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { paymentControllerCreate } from '../fn/payments/payment-controller-create';
import { PaymentControllerCreate$Params } from '../fn/payments/payment-controller-create';
import { paymentControllerFindAllByCompanyId } from '../fn/payments/payment-controller-find-all-by-company-id';
import { PaymentControllerFindAllByCompanyId$Params } from '../fn/payments/payment-controller-find-all-by-company-id';
import { paymentControllerFindOne } from '../fn/payments/payment-controller-find-one';
import { PaymentControllerFindOne$Params } from '../fn/payments/payment-controller-find-one';
import { paymentControllerUpdate } from '../fn/payments/payment-controller-update';
import { PaymentControllerUpdate$Params } from '../fn/payments/payment-controller-update';
import { PaymentSettingsEntity } from '../models/payment-settings-entity';

@Injectable({ providedIn: 'root' })
export class PaymentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `paymentControllerFindAllByCompanyId()` */
  static readonly PaymentControllerFindAllByCompanyIdPath = '/api/v1/payments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerFindAllByCompanyId()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindAllByCompanyId$Response(
    params: PaymentControllerFindAllByCompanyId$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: {
        total?: number;
        limit?: number;
        page?: number;
        items?: Array<PaymentSettingsEntity>;
      };
      message?: string;
    }>
  > {
    return paymentControllerFindAllByCompanyId(
      this.http,
      this.rootUrl,
      params,
      context
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerFindAllByCompanyId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindAllByCompanyId(
    params: PaymentControllerFindAllByCompanyId$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<PaymentSettingsEntity>;
    };
    message?: string;
  }> {
    return this.paymentControllerFindAllByCompanyId$Response(
      params,
      context
    ).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: {
              total?: number;
              limit?: number;
              page?: number;
              items?: Array<PaymentSettingsEntity>;
            };
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: {
            total?: number;
            limit?: number;
            page?: number;
            items?: Array<PaymentSettingsEntity>;
          };
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `paymentControllerCreate()` */
  static readonly PaymentControllerCreatePath = '/api/v1/payments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerCreate$Response(
    params: PaymentControllerCreate$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: PaymentSettingsEntity;
      message?: string;
    }>
  > {
    return paymentControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerCreate(
    params: PaymentControllerCreate$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: PaymentSettingsEntity;
    message?: string;
  }> {
    return this.paymentControllerCreate$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: PaymentSettingsEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: PaymentSettingsEntity;
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `paymentControllerFindOne()` */
  static readonly PaymentControllerFindOnePath = '/api/v1/payments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindOne$Response(
    params: PaymentControllerFindOne$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: PaymentSettingsEntity;
      message?: string;
    }>
  > {
    return paymentControllerFindOne(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindOne(
    params: PaymentControllerFindOne$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: PaymentSettingsEntity;
    message?: string;
  }> {
    return this.paymentControllerFindOne$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: PaymentSettingsEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: PaymentSettingsEntity;
          message?: string;
        } => r.body
      )
    );
  }

  /** Path part for operation `paymentControllerUpdate()` */
  static readonly PaymentControllerUpdatePath = '/api/v1/payments/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerUpdate$Response(
    params: PaymentControllerUpdate$Params,
    context?: HttpContext
  ): Observable<
    StrictHttpResponse<{
      state?: boolean;
      data?: PaymentSettingsEntity;
      message?: string;
    }>
  > {
    return paymentControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paymentControllerUpdate(
    params: PaymentControllerUpdate$Params,
    context?: HttpContext
  ): Observable<{
    state?: boolean;
    data?: PaymentSettingsEntity;
    message?: string;
  }> {
    return this.paymentControllerUpdate$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<{
            state?: boolean;
            data?: PaymentSettingsEntity;
            message?: string;
          }>
        ): {
          state?: boolean;
          data?: PaymentSettingsEntity;
          message?: string;
        } => r.body
      )
    );
  }
}
