import { Routes } from '@angular/router';
import { CompanyCreatePage, CompanyEditPage, CompanyListPage } from './pages';

export const CompanyRoute: Routes = [
  {
    path: '',
    component: CompanyListPage,
    data: { breadcrumb: 'man.menu.settings' }
  },
  {
    path: 'edit/:id',
    component: CompanyEditPage,
    data: { breadcrumb: 'Edit' }
  },
  {
    path: 'create',
    component: CompanyCreatePage,
    data: { breadcrumb: 'man.menu.settings' }
  }
];
