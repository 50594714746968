import { Component, inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment, environment as env } from './../environments/environment';
import {
  Router,
  RouterLink,
  RouterOutlet
} from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { TuiNavigation } from '@taiga-ui/layout';
import {
  TuiButton,
  TuiDataList,
  TuiDropdown,
  TuiIcon,
  TuiLink,
  TuiRoot
} from '@taiga-ui/core';
import {
  TuiAvatar,
  TuiBadge,
  TuiBadgeNotification,
  TuiBreadcrumbs,
  TuiChevron,
  TuiDataListDropdownManager,
  TuiFade
} from '@taiga-ui/kit';
import { ThemeSwitcherComponent } from '@shared/theme-switcher/theme-switcher.component';
import { ImageUrlPipe } from '@core/pipes/image-url.pipe';
import {
  TuiSelectModule
} from '@taiga-ui/legacy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { LanguageService } from '@core/services/language.service';
import { ThemeService } from '@core/services/theme.service';
import { AuthCompanyService } from '@core/services/auth-company.service';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { TuiItem } from '@taiga-ui/cdk';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  providers: [],
  imports: [
    TuiRoot,
    ThemeSwitcherComponent,
    TuiNavigation,
    TuiButton,
    TuiIcon,
    TuiChevron,
    TuiDropdown,
    TuiFade,
    TuiDataList,
    TuiBadgeNotification,
    TuiAvatar,
    RouterLink,
    TuiDataListDropdownManager,
    ImageUrlPipe,
    TuiBreadcrumbs,
    RouterOutlet,
    TuiSelectModule,
    TuiLink,
    AsyncPipe,
    TranslateModule,
    TuiBadge,
    TuiItem
  ]
})
export class AppComponent implements OnInit {
  public router = inject(Router);
  private authService = inject(AuthService);
  private languageService = inject(LanguageService);
  private themeService = inject(ThemeService);
  private authCompanyService = inject(AuthCompanyService);
  private breadcrumbsService = inject(BreadcrumbsService);


  protected readonly environment = environment;
  version = env.versions.app;
  languages: string[] = ['en', 'uk', 'de', 'fr'];
  language = 'en';
  menu = [
    {
      title: 'Members',
      icon: '@tui.users',
      link: 'members'
    },
    {
      title: 'Events',
      icon: '@tui.airplay',
      link: 'events'
    },
    {
      title: 'Payments settings',
      icon: '@tui.tag',
      link: 'payments'
    }
  ];

  theme$: Observable<string> = this.themeService.theme$;
  user$: Observable<any> = this.authService.user$;
  activeCompany$: Observable<any> = this.authCompanyService.company$;
  breadcrumbs$: Observable<any> = this.breadcrumbsService.breadcrumbs$;

  protected expanded = false;
  public openLanguage = false;
  public openCompany = false;
  public openUser = false;


  async ngOnInit() {
    this.language = this.languageService.current();
  }

  async onLogoutClick() {
    await this.authService.logout();
    this.authCompanyService.reset();
    await this.router.navigateByUrl('auth');
    this.openUser = false;
  }

  public async onEditCompany(id: string) {
    await this.router.navigateByUrl(`/companies/edit/${id}`);
    this.openCompany = false;
  }

  public async onResetCompany() {
    this.authCompanyService.reset();
    await this.router.navigateByUrl('/companies');
    this.openCompany = false;
  }

  public selectLanguage(ln: any) {
    this.language = ln;
    this.languageService.set(ln);
    this.openLanguage = false;
  }
}
