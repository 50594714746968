import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  signal,
  WritableSignal
} from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { TuiButton, TuiDialogService, TuiIcon, TuiLoader, TuiSurface, TuiTitle } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { finalize } from 'rxjs/operators';
import { TicketTiersService } from '@core/api/services';
import { TierEntity } from '@core/api/models/tier-entity';
import { EventTicketTierModal } from '../../modals/event-ticket-tier/event-ticket-tier.modal';
import { AsyncPipe, SlicePipe } from '@angular/common';
import {
  TuiTableCell,
  TuiTableDirective,
  TuiTableTbody,
  TuiTableTd,
  TuiTableTh,
  TuiTableThGroup,
  TuiTableTr
} from '@taiga-ui/addon-table';
import { TuiCurrencyPipe } from '@taiga-ui/addon-commerce';
import { EmptySectionWithActionComponent } from '@shared/empty-section-with-action/empty-section-with-action.component';
import { TuiCardLarge, TuiCell, TuiHeader } from '@taiga-ui/layout';
import { BaseAbstractComponent } from '../../../base.abstract.component';

@Component({
  selector: 'event-ticket-tiers-list-component',
  templateUrl: './event-ticket-tiers-list.component.html',
  styleUrls: ['./event-ticket-tiers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiLoader,
    AsyncPipe,
    TuiButton,
    TuiTableDirective,
    TuiTableTh,
    TuiTableThGroup,
    TuiTableTbody,
    TuiTableTr,
    TuiTableCell,
    TuiTableTd,
    TuiCurrencyPipe,
    EmptySectionWithActionComponent,
    TuiIcon,
    TuiCardLarge,
    TuiSurface,
    TuiCell,
    SlicePipe,
    TuiHeader,
    TuiTitle
  ]
})
export class EventTicketTiersListComponent extends BaseAbstractComponent implements OnInit {
  @Input() eventId!: string;
  private ticketTiersService = inject(TicketTiersService);
  private dialogs = inject(TuiDialogService);
  public eventTicketTiers$: Observable<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<TierEntity>;
    };
    message?: string;
  }>;
  public readonly displayedColumns: string[] = [
    'info',
    'price',
    'qty',
    'desc',
    'active',
    'actions'
  ];

  ngOnInit(): void {
    this.loadData();

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  loadData() {
    this.showLoader.set(true);
    this.eventTicketTiers$ = this.ticketTiersService
      .ticketsTiersControllerGetAll({
        event_id: this.eventId,
        limit: 50,
        page: 1
      })
      .pipe(finalize(() => this.showLoader.set(false)));
  }

  add(tier?: TierEntity) {
    this.dialogs
      .open<boolean>(new PolymorpheusComponent(EventTicketTierModal), {
        data: {
          event_id: this.eventId,
          tier: tier || null
        },
        dismissible: true,
        appearance: 'custom-modal'
      })
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (data) => {
          if (data) {
            this.loadData();
          }
          console.info(`Dialog emitted data = ${data}`);
        }
      });
  }
}
