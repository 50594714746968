<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div class="container" [formGroup]="resetPasswordForm">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <tui-island class="with-bg" style="background-image: url('assets/auth-bg-2.jpg')">
          <div class="logo-holder">
            <img src="assets/password.svg">
          </div>
        </tui-island>
      </div>
      <div class="col-xs-12 col-md-6">
        <tui-island>
          <div class="form-holder">
            <h1 class="tui-space_bottom-2 center-xs">{{ 'Сброс паролю' | translate }}</h1>
            <p class="tui-space_bottom-8 center-xs">Введіть новий пароль</p>
            <tui-input-password class="tui-space_bottom-4" formControlName="password">
              Type an password
              <input
                tuiTextfieldLegacy
                id="password"
                name="password"
                type="password"
              />
            </tui-input-password>
            <tui-input-password class="tui-space_bottom-4" formControlName="confirmedPassword">
              Type an password
              <input
                tuiTextfieldLegacy
                id="confirmedPassword"
                name="confirmedPassword"
                type="password"
              />
            </tui-input-password>

            <footer>
              <button
                tuiButton
                type="button"
                appearance="primary"
                [disabled]="resetPasswordForm.invalid"
                (click)="resetPassword()"
                class="sign-in-btn tui-space_bottom-7"
              >
                Reset password
              </button>
              <button
                tuiLink
                type="button"
                routerLink="/auth/sign-in"
                class="styled-link"
              >
                Cancel
              </button>
            </footer>
          </div>
        </tui-island>
      </div>
    </div>
  </div>
</tui-loader>
