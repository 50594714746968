<tui-island>
  <app-page-header
    [title]="'anms.settings.title' | translate"
    [underline]='true'
  ></app-page-header>
  @if (inviteForm) {
    <ng-container [formGroup]='inviteForm'>
      <div class='row'>
        <div class='col-md-6'>
          <h2 class='tui-text_h2 tui-space_bottom-4'>Name of event</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
        </div>
        <div class='col-md-6'>
          <tui-input
            class='tui-space_vertical-4'
            formControlName='email'>
            Type an email
            <input
              tuiTextfieldLegacy
              type='email'
              />
          </tui-input>
          <tui-select
            tuiTextfieldSize='l'
            class='tui-space_vertical-4'
            formControlName='role'
            [tuiTextfieldLabelOutside]='true'
            >
            Member role
            <input
              tuiTextfieldLegacy
              placeholder='Choose event type'
              />
            <tui-data-list-wrapper
              *tuiDataList
              [items]='companyMemberRoles'
            ></tui-data-list-wrapper>
          </tui-select>
        </div>
      </div>
      <div class='row'>
        <div class='col-md-6'>
          <button tuiButton
            appearance='secondary'
            type='button'
            size='l'
            class='tui-space_vertical-4'
            (click)='back()'
            >Cancel
          </button>
        </div>
        <div class='col-md-6 end-xs'>
          <button tuiButton
            type='button'
            size='l'
            class='tui-space_vertical-4'
            (click)='sendInvite()'
            [disabled]='inviteForm.invalid'
            >Invite
          </button>
        </div>
      </div>
    </ng-container>
  }
</tui-island>


