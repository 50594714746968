<tui-loader [overlay]="true" [showLoader]="showLoader()">
  <div class="container" [formGroup]="signUpForm">
    <div class="row">
      <div class="col-xs-12 col-md-6">
        <tui-island class="with-bg" style="background-image: url('assets/auth-bg-2.jpg')">
          <div class="logo-holder">
            <img src="assets/single-logo-white.svg">
          </div>
        </tui-island>
      </div>
      <div class="col-xs-12 col-md-6">
        <tui-island>
          <div class="form-holder">
            <h1 class="tui-space_bottom-2 center-xs">{{ 'auth#sign_up#titile' | translate }}</h1>
            <p class="tui-space_bottom-8 center-xs">{{ 'auth#sign_up#description' | translate }}</p>
            <div class="tui-space_bottom-4">
              <tui-input
                formControlName="email"
                tuiTextfieldSize="m"
                [tuiTextfieldCleaner]="true"
              >
                {{ 'auth#sign_up#e-mail' | translate }}
                <input
                  autocomplete="email"
                  tuiTextfieldLegacy
                  type="email"
                />
              </tui-input>
              <tui-error
                formControlName="email"
                [error]="[] | tuiFieldError | async"
              />
            </div>
            <div class="tui-space_bottom-4">
              <tui-input-password formControlName="password">
                {{ 'auth#sign_up#password' | translate }}
                <input
                  tuiTextfieldLegacy
                  id="password"
                  name="password"
                  type="password"
                />
              </tui-input-password>
              <tui-error
                formControlName="password"
                [error]="[] | tuiFieldError | async"
              />
            </div>
            <div class="tui-space_bottom-4">
              <tui-input-password formControlName="confirmPassword">
                {{ 'auth#sign_up#confirm_password' | translate }}
                <input
                  tuiTextfieldLegacy
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                />
              </tui-input-password>
              <tui-error
                formControlName="confirmPassword"
                [error]="[] | tuiFieldError | async"
              />
            </div>
            <footer>
              <button
                tuiButton
                type="button"
                appearance="primary"
                [disabled]="signUpForm.invalid"
                (click)="singUp()"
                class="sign-in-btn tui-space_bottom-7"
              >
                {{ 'auth#sign_up#register' | translate }}
              </button>
              <button
                tuiLink
                type="button"
                routerLink="/auth/sign-in"
                class="styled-link"
              >
                {{ 'auth#sign_up#back_to_login' | translate }}
              </button>
            </footer>
          </div>
        </tui-island>
      </div>
    </div>
  </div>
</tui-loader>
