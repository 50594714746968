/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CreateTierDto } from '../../models/create-tier-dto';
import { TierEntity } from '../../models/tier-entity';

export interface TicketsTiersControllerCreate$Params {
  event_id: string;
  body: CreateTierDto;
}

export function ticketsTiersControllerCreate(
  http: HttpClient,
  rootUrl: string,
  params: TicketsTiersControllerCreate$Params,
  context?: HttpContext
): Observable<
  StrictHttpResponse<{
    state?: boolean;
    data?: TierEntity;
    message?: string;
  }>
> {
  const rb = new RequestBuilder(
    rootUrl,
    ticketsTiersControllerCreate.PATH,
    'post'
  );
  if (params) {
    rb.path('event_id', params.event_id, {});
    rb.body(params.body, 'application/json');
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
          state?: boolean;
          data?: TierEntity;
          message?: string;
        }>;
      })
    );
}

ticketsTiersControllerCreate.PATH = '/api/v1/tiers/{event_id}';
