import { Injectable } from '@angular/core';
import { Observable, from, catchError, BehaviorSubject, throwError } from 'rxjs';
import { signIn } from 'supertokens-web-js/lib/build/recipe/emailpassword';
import { tap } from 'rxjs/operators';
import Session from 'supertokens-web-js/recipe/session';
import { signUp } from 'supertokens-web-js/recipe/emailpassword';
import {
  getAuthorisationURLWithQueryParamsAndSetState,
  signInAndUp
} from 'supertokens-web-js/lib/build/recipe/thirdparty';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public user$: Observable<any> = this.userSubject.asObservable();

  constructor() {
    this.getUserFormSession().then();
  }

  token$() {
    return from(Session.getAccessToken());
  }

  async getUserFormSession() {
    if (await this.doesSessionExist()) {
      const payload = await Session.getAccessTokenPayloadSecurely();
      this.updateUser(payload)
    }
  }

  async doesSessionExist() {
    return await Session.doesSessionExist();
  }

  signIn(email: string, password: string): Observable<any> {
    return from(signIn({
      formFields: [{
        id: 'email',
        value: email
      }, {
        id: 'password',
        value: password
      }]
    })).pipe(
      tap(response => {
        if (response.status === 'OK') {
          this.updateUser(response.user);
          return;
        }

        let errorMessage = this.extractErrorMessage(response);
        throw new Error(errorMessage);
      }),
      catchError(err => {
        let errorMessage = this.extractErrorMessage(err);
        return throwError(() => new Error(errorMessage)); // Повернуть обсервабл з помилкою
      })
    );
  }

  private extractErrorMessage(err: any): string {
    if (err.status === 'FIELD_ERROR') {
      return err.formFields.map((formField: any) => formField.error).join(', ');
    }
    if (err.status === 'WRONG_CREDENTIALS_ERROR') {
      return 'Email password combination is incorrect.';
    }

    if (err.status === 'SIGN_UP_NOT_ALLOWED') {
      return 'Sign up not allowed';
    }

    if (err.status === 'SIGN_IN_UP_NOT_ALLOWED') {
      return 'Sign in up not allowed';
    }

    if (err.status === 'SIGN_IN_NOT_ALLOWED') {
      return err.reason;
    }
    return 'Oops! Something went wrong.';
  }

  signUp(email: string, password: string) {
    return from(signUp({
      formFields: [{
        id: 'email',
        value: email
      }, {
        id: 'password',
        value: password
      }]
    })).pipe(
      tap(response => {
        if (response.status === 'OK') {
          this.updateUser(response.user);
          return;
        }

        let errorMessage = this.extractErrorMessage(response);
        throw new Error(errorMessage);
      }),
      catchError(err => {
        let errorMessage = this.extractErrorMessage(err);
        return throwError(() => new Error(errorMessage)); // Повернуть обсервабл з помилкою
      })
    );
  }

  signInThirdParty(type: string) {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    const fullUrl = `${protocol}//${hostname}${port ? ':' + port : ''}`;
    return from(getAuthorisationURLWithQueryParamsAndSetState({
      thirdPartyId: type,
      frontendRedirectURI: `${fullUrl}/auth/callback/${type}`,
      redirectURIOnProviderDashboard: `${environment.auth.appInfo.apiDomain}/auth/callback/${type}`,
    })).pipe(
      catchError(err => {
        let errorMessage = this.extractErrorMessage(err);
        return throwError(() => new Error(errorMessage)); // Повернуть обсервабл з помилкою
      })
    )
  }

  signInThirdPartyCallback() {
    return from(signInAndUp()).pipe(
      tap(response => {
        if (response.status === 'OK') {
          this.updateUser(response.user);
          return;
        }

        let errorMessage = this.extractErrorMessage(response);
        throw new Error(errorMessage);
      }),
      catchError(err => {
        console.log(err)
        let errorMessage = this.extractErrorMessage(err);
        return throwError(() => new Error(errorMessage)); // Повернуть обсервабл з помилкою
      })
    );
  }

  updateUser(user: any): void {
    this.userSubject.next(user);
  }

  async logout() {
    await Session.signOut()
    this.updateUser(null);
  }

}
