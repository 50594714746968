import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject, OnInit
} from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@taiga-ui/polymorpheus';
import {
  TuiButton,
  TuiDialogContext,
  TuiIcon,
  TuiLabel, TuiLoader,
  TuiTextfieldOptionsDirective
} from '@taiga-ui/core';
import { AddEventProgramForm, AddEventTicketTierForm } from '../../forms';
import { TuiCheckbox, tuiCreateTimePeriods } from '@taiga-ui/kit';
import { TicketTiersService } from '@core/api/services';
import { ReactiveFormsModule } from '@angular/forms';

import {
  TuiInputDateModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputTimeModule,
  TuiSelectModule,
  TuiTextareaModule
} from '@taiga-ui/legacy';
import { BaseAbstractModal } from '../../../base.abstract.modal';
import { takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TierEntity } from '@core/api/models/tier-entity';

@Component({
  selector: 'event-ticket-tier-component',
  templateUrl: './event-ticket-tier.modal.html',
  styleUrls: ['./event-ticket-tier.modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    TuiLabel,
    TuiCheckbox,
    TuiInputModule,
    TuiTextareaModule,
    TuiInputNumberModule,
    TuiSelectModule,
    TuiInputDateModule,
    TuiInputTimeModule,
    TuiTextfieldOptionsDirective,
    TuiButton,
    TuiIcon,
    TuiLoader
  ],
  standalone: true
})
export class EventTicketTierModal extends BaseAbstractModal implements OnInit {
  public form: AddEventTicketTierForm;
  private ticketTiersService = inject(TicketTiersService);
  readonly currencies = ['USD', 'UAH', 'EUR'];
  public timePeriod = tuiCreateTimePeriods();

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      boolean,
      {
        event_id: string;
        tier: TierEntity | null;
      }
    >
  ) {
    super();
    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  ngOnInit() {
    const { tier } = this.data;

    if (!tier) {
      this.form = new AddEventTicketTierForm();
      this.showLoader.set(false);
    }

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  get data(): {
    event_id: string;
    tier: TierEntity | null,
  } {
    return this.context.data;
  }

  public onSubmit() {
    this.showLoader.set(true);
    console.log(this.form.value.sales_end_date);
    if (!this.form.value) {
      return;
    }

    const sales_end_date = new Date(
      this.form.endDate.value.year,
      this.form.endDate.value.month,
      this.form.endDate.value.day,
      this.form.endTime.value.hours,
      this.form.endTime.value.minutes,
      this.form.endTime.value.seconds
    );

    this.ticketTiersService
      .ticketsTiersControllerCreate({
        event_id: this.data.event_id,
        body: {
          currency: this.form.value.currency!,
          description: this.form.value.description!,
          name: this.form.value.name!,
          price: this.form.value.price!,
          sales_end_date: sales_end_date.toUTCString(),
          total_quantity: this.form.value.total_quantity!,
          termless: this.form.value.termless!,
          active: this.form.value.active!
        }
      })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showLoader.set(false))
      )
      .subscribe((res) => this.context.completeWith(true));
  }

  public onCancel() {
    this.context.completeWith(false);
  }
}
