import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'objectUrl'
})
export class ObjectUrlPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): unknown {
    return URL.createObjectURL(value);
  }
}
