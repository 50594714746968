/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TierEntity } from '../../models/tier-entity';

export interface TicketsTiersControllerGetAll$Params {
  event_id: string;
  limit: number;
  page: number;

  /**
   * Find By #
   */
  name?: string;
}

export function ticketsTiersControllerGetAll(
  http: HttpClient,
  rootUrl: string,
  params: TicketsTiersControllerGetAll$Params,
  context?: HttpContext
): Observable<
  StrictHttpResponse<{
    state?: boolean;
    data?: {
      total?: number;
      limit?: number;
      page?: number;
      items?: Array<TierEntity>;
    };
    message?: string;
  }>
> {
  const rb = new RequestBuilder(
    rootUrl,
    ticketsTiersControllerGetAll.PATH,
    'get'
  );
  if (params) {
    rb.path('event_id', params.event_id, {});
    rb.query('limit', params.limit, {});
    rb.query('page', params.page, {});
    rb.query('name', params.name, {});
  }

  return http
    .request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    )
    .pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
          state?: boolean;
          data?: {
            total?: number;
            limit?: number;
            page?: number;
            items?: Array<TierEntity>;
          };
          message?: string;
        }>;
      })
    );
}

ticketsTiersControllerGetAll.PATH = '/api/v1/tiers/{event_id}';
