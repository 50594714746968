import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@core/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthCompanyService {
  private localStorageService = inject(LocalStorageService)

  private companySubject: BehaviorSubject<any> = new BehaviorSubject<any>(this.localStorageService.getItem('company'));
  public company$: Observable<any> = this.companySubject.asObservable();

  constructor() {
  }


  public current() {
    return this.companySubject.value;
  }

  public set(company: any) {
    this.localStorageService.setItem('company', company);
    this.companySubject.next(company);
  }

  public reset() {
    this.localStorageService.removeItem('company');
    this.companySubject.next(null);
  }
}
