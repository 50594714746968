import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupDef } from '@core/utils';
import { CreateEventProgramDto } from '@core/api/models/create-event-program-dto';

export class AddEventProgramForm extends FormGroup<
  FormGroupDef<Partial<CreateEventProgramDto>>
> {
  constructor() {
    super({
      title: new FormControl('', { nonNullable: true }),
      description: new FormControl('', { nonNullable: true }),
      short_description: new FormControl('', { nonNullable: true }),
      time: new FormControl('', { nonNullable: true }),
      order: new FormControl(0, { nonNullable: true })
    });

    this.controls.title?.addValidators([Validators.required]);
    this.controls.description?.addValidators([Validators.required]);
    this.controls.short_description?.addValidators([Validators.required]);
    this.controls.time?.addValidators([Validators.required]);
  }

  addImageControl() {
    const imageControl = new FormControl(null);
    this.addControl('img', imageControl);
  }
}
